import React from "react";
import Sortable from "react-drag-sort";
import qs from "query-string";
import axios from "../hoc/axios";
import CurrencyFormat from 'react-currency-format';
import {
  GET_ITEMS_BY_GROUP,
  UPDATE_ITEMS_ORDER,
  POST_METHOD,
} from "../constants/apis";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ADD_PROGRESS } from "../store/actions/LoaderActions";

class Demo extends React.Component {
  state = {
    collection: [],
    loader: false,
  };

  componentDidMount() {
    const parsed = qs.parse(this.props.location.search);
    axios(
      POST_METHOD,
      GET_ITEMS_BY_GROUP,
      { group_id: parsed.id },
      this.props.setLoadingProgress
    ).then((Response) => {
      /*eslint-disable-next-line */
      if (Response.success) {
        var finalArray = [];
        Response.data.data.map((Element, Index) => {
          finalArray.push({ key: Index, value: { ...Element } });
        });

        this.setState({ collection: finalArray });
      }
    });

    this.setState({
      collection: [],
      loader: true,
    });
  }



  Item({
    image,
    price,
    name,
    value,
    index,
    onChange,
    onRemove,
    decorateHandle,
  }) {
    const triggerRedirect = (id) => {
      window.location.href = "/Items/Edit?id="+id;
    };
    return (
      <div className="row">
        <div
          className="col-md-12"
          style={{
            padding: "10px 40px",
          }}
        >
          <div
            style={{
              boxShadow: "0px 0px 5px -1px rgba(0,0,0,0.75)",
              borderRadius: "10px",
              overflow: "hidden",
              padding: "10px",
            }}
          >
            {/* {decorateHandle(
              <span style={{ cursor: "grab" }}>
                <svg
                  width="2.5em"
                  height="2.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-grip-horizontal"
                  fill="currentColor"
                  style={{ color: "#f05a28", marginTop: "-12px" }}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                </svg>
              </span>
            )} */}

            <div className="list-container" key={value.id} onClick={()=> triggerRedirect(value.id)}>
                <div className="items-listing">
                    <div className="item-image">
                        <img src={ value.image } alt="food item" />
                    </div>
                    <div className="items-details">
                        <h4>{ value.name }</h4>
                        <ul>
                            <li>{ value.description }</li>
                            <li><CurrencyFormat value={value.price} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}</div>} /></li>
                            <li>{ value.group_name }</li>
                        </ul>
                    </div>
                </div>
            </div>

          </div>
        </div>
      </div>
    );
  }

  render() {
    const SubmitUpdateHandler = () => {
      var data = [];

      /*eslint-disable-next-line*/
      this.state.collection.map((Element, Index) => {
        data.push({ sort_id: Index + 1, id: Element.value.id });
      });

      axios(
        POST_METHOD,
        UPDATE_ITEMS_ORDER,
        data,
        this.props.setLoadingProgress
      ).then((Response) => {
        if (Response.success) {
          return this.setState({ DragComponentSuccess: true });
        } else {
          this.setState({
            DragComponentError: Response.message,
          });
        }
      });
    };
    const { DragComponentError, DragComponentSuccess } = this.state;

    return (
      <>
        <Sortable
          collection={this.state.collection}
          onChange={(collection) => {
            this.setState({ collection });
          }}
          Component={this.Item}
        />
        <br />
        {this.state.loader === true && this.state.collection.length === 0 ? (
          <div style={{ padding: "10px 40px" }}>
            No items Found
            <br />
            <br />
            <Link to="/Items/Add">
              <button>Add an Item</button>
            </Link>
          </div>
        ) : null}
        {/*{this.state.loader === true && this.state.collection.length !== 0 ? (
          <div style={{ padding: "10px 40px" }}>
            <button
              onClick={() => {
                SubmitUpdateHandler();
              }}
            >
              Update Priority
            </button>
          </div>
        ) : null}{" "} */}
        {DragComponentError ? (
          <div className="ErrorsModule">
            <span className="text-muted">
              <svg
                width="1.3em"
                height="1.3em"
                style={{ marginTop: "-3px" }}
                viewBox="0 0 16 16"
                className="bi bi-bug"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.355.522a.5.5 0 0 1 .623.333l.291.956A4.979 4.979 0 0 1 8 1c1.007 0 1.946.298 2.731.811l.29-.956a.5.5 0 1 1 .957.29l-.41 1.352A4.985 4.985 0 0 1 13 6h.5a.5.5 0 0 0 .5-.5V5a.5.5 0 0 1 1 0v.5A1.5 1.5 0 0 1 13.5 7H13v1h1.5a.5.5 0 0 1 0 1H13v1h.5a1.5 1.5 0 0 1 1.5 1.5v.5a.5.5 0 1 1-1 0v-.5a.5.5 0 0 0-.5-.5H13a5 5 0 0 1-10 0h-.5a.5.5 0 0 0-.5.5v.5a.5.5 0 1 1-1 0v-.5A1.5 1.5 0 0 1 2.5 10H3V9H1.5a.5.5 0 0 1 0-1H3V7h-.5A1.5 1.5 0 0 1 1 5.5V5a.5.5 0 0 1 1 0v.5a.5.5 0 0 0 .5.5H3c0-1.364.547-2.601 1.432-3.503l-.41-1.352a.5.5 0 0 1 .333-.623zM4 7v4a4 4 0 0 0 3.5 3.97V7H4zm4.5 0v7.97A4 4 0 0 0 12 11V7H8.5zM12 6H4a3.99 3.99 0 0 1 1.333-2.982A3.983 3.983 0 0 1 8 2c1.025 0 1.959.385 2.666 1.018A3.989 3.989 0 0 1 12 6z"
                />
              </svg>
              &nbsp; Please fix the following issues :
            </span>
            <br />
            {Object.values(DragComponentError).map((Element, Index) => {
              return (
                <span key={Index}>
                  {Index + 1 + " " + Element}
                  <br />
                </span>
              );
            })}
          </div>
        ) : null}
        {DragComponentSuccess ? (
          <div className="ErrorsModule">
            <span className="text-muted">
              <svg
                width="1.3em"
                height="1.3em"
                style={{ marginTop: "-3px" }}
                viewBox="0 0 16 16"
                className="bi bi-check-circle"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                />
                <path
                  fillRule="evenodd"
                  d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                />
              </svg>{" "}
              &nbsp; Items sorted successfully.
            </span>
          </div>
        ) : null}
      </>
    );
  }
}
const dispatchToProps = (dispatch) => {
  return {
    setLoadingProgress: (progress) => {
      dispatch({ type: ADD_PROGRESS, progress });
    },
  };
};

export default connect(null, dispatchToProps)(Demo);
