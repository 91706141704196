import { ADD_PROGRESS } from "../actions/LoaderActions";

const initialState = {
  loaderProgress: 0,
};

const UserDetailsReducer = (state = initialState, action) => {
  //eslint-disable-next-line
  switch (action.type) {
    case ADD_PROGRESS: {
      return { ...state, loaderProgress: action.progress };
    }
  }

  return state;
};

export default UserDetailsReducer;
