/*============================================================================================
                       REDUCER FOR AUTHENTICATION
===============================================================================================*/
import Cookies from "universal-cookie";
const cookie = new Cookies();

export const LOGOUT = "LOGOUT";
export const LOGIN = "LOGIN";

//LOG IN AND SAVE COOKIE
export const loginUser = (remember, data) => {
  return function (dispatch) {
    var expiration = remember ? new Date(Date.now() + 315569520315) : null;
    console.log(data);
    cookie.set("MEKey", data.access_token, {
      path: "/",
      expires: expiration,
    });

    cookie.set("MEEmail", data.email, {
      path: "/",
      expires: expiration,
    });

    dispatch({ type: LOGIN });
  };
};

//LOGOUT
export const logoutUser = () => {
  return function (dispatch) {
    cookie.remove("MEKey");
    cookie.remove("MEEmail");
    dispatch({ type: LOGOUT });
    console.log('qwerty');
    window.location.href = "/Login";
  };
};
