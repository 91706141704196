/*============================================================================================
                                    API URLS
===============================================================================================*/

export const BASE_URL = "https://molieats.com/laravel/public/api/";
export const VENDOR_LOGIN = "login";
export const VENDOR_REGISTRATION = "signup";
export const VENDOR_STORE = "store";
export const GET_ALL_GROUPS = "group/list/";
export const GET_ALL_ITEMS = "item/list";
export const ITEM = "item/add";
export const GET_PARTICULAR_ITEM = "item";
export const ITEM_IMAGES = "item/images";
export const EDIT_ITEM = "item/edit";
export const DELETE_IMAGE = "delete/item/image";
export const CREATE_GROUP = "group/create";
export const DELETE_GROUP = "group/delete";
export const GET_ITEMS_BY_GROUP = "group/item/list";
export const UPDATE_ITEMS_ORDER = "item/sort";
export const DELETE_ITEM = "item/delete";
export const EDIT_GROUP = "group/edit";
export const GROUP_INFO = "group/info";
export const DELIVERY_ADD = "delivery/address";
export const DELIVERY_LIST = "driver/list";
export const ORDER_LIST = "order/list";
export const CHANGE_ROLE = "change/role";
export const USER = "user";
export const HOME = "home";
export const START_CHAT = "chat/start";
export const CHAT_NOTIFICATION = "chat/notification";
export const ORDER_UPDATE = "order/update";
export const GET_USER = "getUser";
export const ADD_ORDER = "order/add";
export const DELIVERY_REQ = "delivery/request";
export const REQ_STATUS = "request/status";
export const GET_METHOD = "GET";
export const POST_METHOD = "POST";
export const DELETE_METHOD = "DELETE";
export const ITEMS = "items";
export const ITEMINFO = "item";
export const STORE = "store/detail";