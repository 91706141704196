import React, { Component, Fragment } from 'react'; 
import "../styles/style.scss";
import mapImage from "../assets/map.jpg";
import logo from "../assets/logo.png";
import deliveryImg from "../assets/delivery2.png";
import { DELIVERY_LIST, POST_METHOD } from "../constants/apis";
import axios from "../hoc/axios";
import { connect } from "react-redux";
import Header from "../components/Panel/Header";
import { Link } from "react-router-dom";
import { ADD_PROGRESS } from "../store/actions/LoaderActions";
import Classes from "../styles/MyStore/MyStore.module.css";
const { compose, withProps, withStateHandlers } = require("recompose");
//const FaAnchor = require("react-icons/lib/fa/anchor");

const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} = require("react-google-maps");


class MapScreen extends React.Component {
    
    state = {
      AddDriverError: true,
      AddDriverSuccess: false,
      list: {
        rows: [],
      },
    };

    static defaultProps = {
          googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyD4doZrX7pSltqAe8dRnZBcG2N6Ig8QMeU&v=3.exp&libraries=geometry,drawing,places",
        }

    constructor(props) {
        super(props);
    }

    data =  JSON.parse(localStorage.getItem("delivery_data"));

    componentDidMount() {
        // need to make the initial call to getData() to populate
        // data right away
        this.getData();

        // Now we need to make it run at a specified interval
        setInterval(this.getData, 5000); // runs every 5 seconds.
      
    }

    getData = () => {
      const reqData = {"latitude": this.data.storeLat, "longitude": this.data.storeLng };

      axios(POST_METHOD, DELIVERY_LIST, reqData , this.props.setLoadingProgress).then(
        (Response) => {
          if (Response.success) {
            /*eslint-disable-next-line*/

            const list = [];
            if(Response.data.data!==undefined){
                Response.data.data.map((Element) => {
                    list.push({
                    ...Element
                  });
                });

                this.setState({
                  AddDriverError:false,
                  AddDriverSuccess:true,
                  list: {
                    rows: list,
                  },
                });
              }
          }
        });
    }

    CMap = compose(
            withStateHandlers(() => ({
              isOpen: true,
            }), {
              onToggleOpen: ({ isOpen }) => () => ({
                isOpen: !isOpen,
              })
            }),
            withScriptjs,
            withGoogleMap
          )(props =>
        <GoogleMap
          google={this.props.google}
          defaultZoom={11}
          defaultCenter={{ lat: this.data.storeLat, lng: this.data.storeLng }}
        >

        <Marker key="center"
            position={{ lat: parseFloat(this.data.storeLat), lng: parseFloat(this.data.storeLng) }}
          />

        {this.state.list.rows.map((marker, i) =>{
          marker.url = "/chat-start?id="+marker.id;
          return ( <Marker key={i} title={marker.first_name+" "+marker.last_name}
            icon={{ url: deliveryImg  }}
            position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) }} onClick={props.onToggleOpen} 
          > {props.isOpen &&<InfoWindow onCloseClick={props.onToggleOpen}>
              <div>
                <img src="https://cdn.beejameditation.com/centers/November2019/Ltrfu1TVSkWw0Iu1g53e.jpg" width="32px" height="32px" />&nbsp;
                  <b>{marker.first_name} {marker.distance} km away</b>
                  <br/>
                  <br/>
                  
                  <a href={marker.url}>Start Chat</a>
              </div>
          </InfoWindow>} </Marker> ) 
        })}
        </GoogleMap>
      );

    handleClick = (marker) => {
      console.log(marker);
      localStorage.setItem('driver', JSON.stringify(marker));
      var user = localStorage.getItem('user');
      console.log(user);
       // axios(POST_METHOD, DELIVERY_LIST, reqData , this.props.setLoadingProgress).then(
       //  (Response) => {
       //    if (Response.success) {
       //      /*eslint-disable-next-line*/

       //      const list = [];
       //      if(Response.data.data!==undefined){
       //          Response.data.data.map((Element) => {
       //              list.push({
       //              ...Element
       //            });
       //          });

       //          this.setState({
       //            AddDriverError:false,
       //            AddDriverSuccess:true,
       //            list: {
       //              rows: list,
       //            },
       //          });
       //        }
       //    }
       //  });

    }

    render() {
        const cancelDelivery = () => {
          window.location.href = '/cart'
        }

        const LinksList = [
          {
            name: "Driver Availability",
            link: "/driver-availability",
          },
        ];

        return (
          <section className={Classes.Parent}>
            <Header LinksList={LinksList} />
            <section className="main-section">
              
                <section className="chart-section">
                    <div className="inner-wrapper">
                        <div className="mapDiv">
                            <Fragment>
                                <this.CMap

                                    googleMapURL={this.props.googleMapURL}
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div style={{ height: `700px` }} />}
                                    mapElement={<div style={{ height: `100%` }} />}
                                    center= {{ lat: this.data.storeLat, lng: this.data.storeLng }} 
                                >
                                    
                                </this.CMap>
                            </Fragment>

                            <div className="route-form">
                               <div className="inner-row">
                                   <div className="row-left-text">From</div>
                                   <div className="row-inner-text">
                                       <p>{ this.data.storeLocation }</p>
                                       <span>Pickup Location</span>
                                   </div>
                               </div>
                               <div className="inner-row">
                                   <div className="row-left-text">To</div>
                                   <div className="row-inner-text">
                                       <p>{ this.data.customerLocation }</p>
                                       <span>Drop Location</span>
                                   </div>
                               </div>
                               <button onClick={cancelDelivery}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
          </section>

        )
    }
}

const dispatchToProps = (dispatch) => {
  return {
    setLoadingProgress: (progress) => {
      
    },
  };
};

export default connect(dispatchToProps) (MapScreen);