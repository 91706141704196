import React, { Component } from "react";
import Classes from "../../styles/Header.module.css";
import { connect } from "react-redux";
import { NavLink, Link } from "react-router-dom";

export class Header extends Component {
  render() {
    const { LinksList } = this.props;

    return (
      <div className="row">
        <div className="col-md-12">
          <div className={Classes.Parent}>
            <div className={Classes.Links}>
              {LinksList.map((Element) => {
                return (
                  <NavLink
                    to={Element.link}
                    key={Element.link}
                    activeClassName={Classes.Active}
                    exact
                  >
                    {Element.name}
                  </NavLink>
                );
              })}
            </div>
            <Link to="/Settings"><span className={Classes.Email}>
              <svg
                width="1.4em"
                height="1.4em"
                viewBox="0 0 16 16"
                className="bi bi-person-fill"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                />
              </svg>
              {this.props.email}
            </span>
            </Link>
            <hr />
          </div>
        </div>
      </div>
    );
  }
}
const stateToProps = (state) => {
  return {
    email: state.auth.email,
  };
};
export default connect(stateToProps)(Header);
