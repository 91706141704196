import React, { Component } from "react";
import Classes from "../../../styles/MyItems/Search.module.css";

export class Search extends Component {
  render() {
    const { searchItems, data } = this.props;
    return (
      <>
        <div className={"InputDialog " + Classes.Parent}>
          <div className="InputIcon">
            <svg
              width="1.3em"
              height="1.3em"
              viewBox="0 0 16 16"
              className="bi bi-search"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
              />
              <path
                fillRule="evenodd"
                d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
              />
            </svg>
          </div>
          <input
            type="text"
            placeholder="Search Item by Item name"
            onChange={(event) => {
              searchItems(event.target.value);
            }}
          />
        </div>
        <div className={Classes.Results}>
          {data.map((Element) => {
            return (
              <a
                href={`/Items/Edit?id=${Element.id}`}
                style={{ color: "inherit" }}
              >
                <div key={Element.id}>
                  {Element.name} <span>{Element.price}</span>
                </div>
              </a>
            );
          })}
        </div>
      </>
    );
  }
}

export default Search;
