/*============================================================================================
                                    VENDOR REGISTRATION
===============================================================================================*/
import React, { Component } from "react";
import Classes from "../styles/RegistrationForm.module.css";
import ReactFormInputValidation from "react-form-input-validation";
import { Link } from "react-router-dom";

class RegistrationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToHome: true,
      fields: {
        email: "",
        password: "",
        cpassword: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        phone: "",
      },
      errors: {},
    };
  }
  render() {
    var onChangeHandler = (event) => {
      this.setState({
        selectedFile: event.target.files[0],
        file: URL.createObjectURL(event.target.files[0]),
      });
    };
    /*============================================================================================
                                    FUNCTIONS
===============================================================================================*/
    const {
      registerStoreOwner,
      RegisterFormError,
      RegisterFormSuccess,
    } = this.props;
    this.form = new ReactFormInputValidation(this);
    // this.form.useRules({
    //   email: "required|email",
    //   first_name: "required",
    //   last_name: "required",
    //   phone: "required|numeric|digits:10",
    //   password: "required",
    //   cpassword: "required",
    // });

    this.form.onformsubmit = (fields) => {
      const re = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;

      if(fields.first_name===""){
        alert("Please enter first name");
        return false;
      }

      if(fields.last_name===""){
        alert("Please enter last name");
        return false;
      }

      if(fields.email===""){
        alert("Please enter email id");
        return false;
      }

      if(re.test(fields.email)===false){
        alert("Please enter valid email id");
        return false;
      }

      if(fields.password===""){
        alert("Please enter password");
        return false;
      }

      if (fields.password !== fields.cpassword) {
        alert("Password does not match");
        return false;
      }else{
        this.setState({ errors: { cpassword: "" } });
        registerStoreOwner(
          { fields, selectedFile: this.state.selectedFile || false },
          this.state.redirectToHome
        );
      }

      if(fields.phone===""){
        alert("Please enter phone number");
        return false;
      }
      
    };

    const handlePhoneChange = (event) => {
      const re = /^[0-9\b]+$/;

      if (re.test(event.target.value)) {
        this.setState({
          fields: {
            ...this.state.fields,
            phone: event.target.value
          }
        });
      }else{
        alert('Please enter valid phone number');
      }
    }
    /*============================================================================================
                                    JSX
===============================================================================================*/
    return (
      <div
        className={
          Classes.Parent + " animate__animated animate__fadeIn animate__slow"
        }
      >
        <form onSubmit={this.form.handleSubmit}>
          <div className={`InputDialog ${Classes.FileUpload}`}>
            <center>
              <img
                src={
                  !this.state.file
                    ? "https://img.icons8.com/pastel-glyph/2x/person-male.png"
                    : this.state.file
                }
                style={{ width: "146px" }}
              />
              <br />
              <input
                type="file"
                onChange={(event) => {
                  onChangeHandler(event);
                }}
              />
              
            </center>
          </div>
          <div className="form-group row login-form-custom">
            <label className="form-label col-form-label col-5">
              First Name <span className="Asterisk">*</span>
            </label>
            <div className="form-field col-6">
              <span>:</span>
              <input
                type="text"
                name="first_name"
                style={{ paddingRight: "0px" }}
                className={this.state.errors.first_name ? "ErrorInput" : null}
                onChange={this.form.handleChangeEvent}
                value={this.state.fields.first_name}
              />
              <label className="error">
                {this.state.errors.first_name
                  ? this.state.errors.first_name
                  : ""}
              </label>
            </div>
          </div>

          <div className="form-group row login-form-custom">
            <label className="form-label col-form-label col-5">
              Middle Name{" "}
            </label>
            <div className="form-field col-6">
              <span>:</span>
              <input
                type="text"
                name="middle_name"
                className={this.state.errors.middle_name ? "ErrorInput" : null}
                onChange={this.form.handleChangeEvent}
                value={this.state.fields.middle_name}
              />
              <label className="error">
                {this.state.errors.middle_name
                  ? this.state.errors.middle_name
                  : ""}
              </label>
            </div>
          </div>

          <div className="form-group row login-form-custom">
            <label className="form-label col-form-label col-5">
              Last Name <span className="Asterisk">*</span>
            </label>
            <div className="form-field col-6">
              <span>:</span>
              <input
                type="text"
                name="last_name"
                className={this.state.errors.last_name ? "ErrorInput" : null}
                onChange={this.form.handleChangeEvent}
                value={this.state.fields.last_name}
              />
              <label className="error">
                {this.state.errors.last_name ? this.state.errors.last_name : ""}
              </label>
            </div>
          </div>

          <div className="form-group row login-form-custom">
            <label className="form-label col-form-label col-5">
              Email <span className="Asterisk">*</span>
            </label>
            <div className="form-field col-6">
              <span>:</span>
              <input
                type="text"
                name="email"
                className={this.state.errors.email ? "ErrorInput" : null}
                onChange={this.form.handleChangeEvent}
                value={this.state.fields.email}
              />
              <label className="error">
                {this.state.errors.email ? this.state.errors.email : ""}
              </label>
            </div>
          </div>

          <div className="form-group row login-form-custom">
            <label className="form-label col-form-label col-5">
              Password <span className="Asterisk">*</span>
            </label>
            <div className="form-field col-6">
              <span>:</span>
              <input
                type="password"
                name="password"
                className={this.state.errors.password ? "ErrorInput" : null}
                onChange={this.form.handleChangeEvent}
                value={this.state.fields.password}
              />
              <label className="error">
                {this.state.errors.password ? this.state.errors.password : ""}
              </label>
            </div>
          </div>

          <div className="form-group row login-form-custom">
            <label className="form-label col-form-label col-5">
              Confirm Password <span className="Asterisk">*</span>
            </label>
            <div className="form-field col-6">
              <span>:</span>
              <input
                type="password"
                name="cpassword"
                className={this.state.errors.cpassword ? "ErrorInput" : null}
                onChange={this.form.handleChangeEvent}
                value={this.state.fields.cpassword}
                data-attribute-name="Confirm Password"
              />
              <label className="error">
                {this.state.errors.cpassword ? this.state.errors.cpassword : ""}
              </label>
            </div>
          </div>

          <div className="form-group row login-form-custom">
            <label className="form-label col-form-label col-5">
              Mobile Number <span className="Asterisk">*</span>
            </label>
            <div className="form-field col-6">
              <span>:</span>
              <input
                type="text"
                name="phone"
                className={this.state.errors.phone ? "ErrorInput" : null}
                onChange={handlePhoneChange}
                value={this.state.fields.phone}
              />
              <label className="error">
                {this.state.errors.phone ? this.state.errors.phone : ""}
              </label>
            </div>
          </div>

          <p
            className="text-muted"
            style={{ margin: "0 10px", textAlign: "center" }}
          >
            Already have an account?
            <Link to="/Login"> Click here</Link>
          </p>

          {RegisterFormError ? (
            <div className="ErrorsModule">
              <span className="text-muted">
                <svg
                  width="1.3em"
                  height="1.3em"
                  style={{ marginTop: "-3px" }}
                  viewBox="0 0 16 16"
                  className="bi bi-bug"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.355.522a.5.5 0 0 1 .623.333l.291.956A4.979 4.979 0 0 1 8 1c1.007 0 1.946.298 2.731.811l.29-.956a.5.5 0 1 1 .957.29l-.41 1.352A4.985 4.985 0 0 1 13 6h.5a.5.5 0 0 0 .5-.5V5a.5.5 0 0 1 1 0v.5A1.5 1.5 0 0 1 13.5 7H13v1h1.5a.5.5 0 0 1 0 1H13v1h.5a1.5 1.5 0 0 1 1.5 1.5v.5a.5.5 0 1 1-1 0v-.5a.5.5 0 0 0-.5-.5H13a5 5 0 0 1-10 0h-.5a.5.5 0 0 0-.5.5v.5a.5.5 0 1 1-1 0v-.5A1.5 1.5 0 0 1 2.5 10H3V9H1.5a.5.5 0 0 1 0-1H3V7h-.5A1.5 1.5 0 0 1 1 5.5V5a.5.5 0 0 1 1 0v.5a.5.5 0 0 0 .5.5H3c0-1.364.547-2.601 1.432-3.503l-.41-1.352a.5.5 0 0 1 .333-.623zM4 7v4a4 4 0 0 0 3.5 3.97V7H4zm4.5 0v7.97A4 4 0 0 0 12 11V7H8.5zM12 6H4a3.99 3.99 0 0 1 1.333-2.982A3.983 3.983 0 0 1 8 2c1.025 0 1.959.385 2.666 1.018A3.989 3.989 0 0 1 12 6z"
                  />
                </svg>
                &nbsp; Please fix the following issues :
              </span>
              <br />
              {Object.values(RegisterFormError).map((Element, Index) => {
                return (
                  <span key={Index}>
                    {Index + 1 + " " + Element}
                    <br />
                  </span>
                );
              })}
            </div>
          ) : null}
          {RegisterFormSuccess ? (
            <div className="ErrorsModule">
              <span className="text-muted">
                <svg
                  width="1.3em"
                  height="1.3em"
                  style={{ marginTop: "-3px" }}
                  viewBox="0 0 16 16"
                  className="bi bi-check-circle"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                  />
                  <path
                    fillRule="evenodd"
                    d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                  />
                </svg>{" "}
                &nbsp; Successfully Registered.
              </span>
            </div>
          ) : null}
          <br />

          {!RegisterFormSuccess ? (
            <div className="row register-buttons">
              <div className="col-6 text-center">
                <button
                  type="submit"
                  onClick={() => {
                    this.setState({ redirectToHome: true });
                  }}
                >
                  Register
                </button>
              </div>
              <div className="col-6 text-center">
                <button
                  type="submit"
                  onClick={() => {
                    this.setState({ redirectToHome: false });
                  }}
                  style={{ marginLeft: "20px" }}
                >
                  Register & Store Setup
                </button>
              </div>
            </div>
          ) : null}

          <br />
          <br />
          {/*{RegisterFormSuccess ? (
            <>
              <div style={{ position: "relative" }}>
                <button>
                  <a href="/StoreRegistration" style={{ color: "#fff" }}>
                    Go to Store
                  </a>
                </button>
              </div>
              <div style={{ position: "relative" }}>
                <button style={{ backgroundColor: "#f05a28" }}>
                  <a href="/Dashboard" style={{ color: "#fff" }}>
                    Go to Dashboard
                  </a>
                </button>
              </div>
            </>
          ) : null} */}
        </form>
      </div>
    );
  }
}

export default RegistrationForm;
