/*============================================================================================
                                    LOGIN FORM COMPONENT
===============================================================================================*/
import React, { Component } from "react";
import Classes from "../styles/LoginForm.module.css";
import { Link } from "react-router-dom";
import ReactFormInputValidation from "react-form-input-validation";

class LoginForm extends Component {
  /*============================================================================================
                                    STATE
===============================================================================================*/
  state = {
    fields: {
      email: "",
      password: "",
    },
    checked: false,
    errors: {},
  };

  render() {
    const { LoginFormError, LoginFormSuccess } = this.props;
    /*============================================================================================
                                    FUNCTIONS
===============================================================================================*/
    this.form = new ReactFormInputValidation(this);

    this.form.useRules({
      email: "email|required",
      password: "required",
    });

    this.form.onformsubmit = (fields) => {
      this.props.loginStoreOwner(fields, this.state.checked);
    };

    /*============================================================================================
                                    JSX
===============================================================================================*/
    return (
            <div
                className={
                    Classes.Parent + " animate__animated animate__fadeIn animate__slow"
                }
            >
                <form onSubmit={this.form.handleSubmit} style={{ padding: "30px" }}>
                    <div className="form-group row login-form-custom">
                        <label className="form-label col-form-label">Email</label>
                        <div className="form-field">
                            <span>:</span>
                            <input type="email"
                                name="email"
                                className={this.state.errors.email ? "ErrorInput" : null}
                                onChange={this.form.handleChangeEvent}
                                value={this.state.fields.email} id="email" />
                        </div>
                    </div>

                    <div className="form-group row login-form-custom">
                        <label className="form-label col-form-label">Password</label>
                        <div className="form-field">
                            <span>:</span>
                            <input
                              type="password"
                              name="password"
                              className={this.state.errors.password ? "ErrorInput" : null}
                              
                              onChange={this.form.handleChangeEvent}
                              value={this.state.fields.password}
                            />
                        </div>
                    </div>




                    <div className="form-group row login-form-custom">
                        <div className="col-form-label"
                            style={{
                                fontSize: "12px"
                            }}
                        >
                            <input
                                type="checkbox"
                                onChange={(event) => {
                                    this.setState({ checked: event.target.checked });
                                }}
                            />{" "}

              Stay signed in
            </div>
                        <div className="form-field">
                            {LoginFormSuccess ? null : <button type="submit"> Login</button>}
                        </div>

                    </div>
                    <p className="text-muted" style={{ margin: "0 10px", textAlign: "center" }}>
                        Do you want to Sign up?
                        <Link to="/Registration"> Click here</Link>
                    </p>
                    <p className="text-muted"></p>

                    {LoginFormError ? (
                        <div className="ErrorsModule">
                            <span className="text-muted">
                                <svg
                                    width="1.3em"
                                    height="1.3em"
                                    style={{ marginTop: "-3px" }}
                                    viewBox="0 0 16 16"
                                    className="bi bi-bug"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4.355.522a.5.5 0 0 1 .623.333l.291.956A4.979 4.979 0 0 1 8 1c1.007 0 1.946.298 2.731.811l.29-.956a.5.5 0 1 1 .957.29l-.41 1.352A4.985 4.985 0 0 1 13 6h.5a.5.5 0 0 0 .5-.5V5a.5.5 0 0 1 1 0v.5A1.5 1.5 0 0 1 13.5 7H13v1h1.5a.5.5 0 0 1 0 1H13v1h.5a1.5 1.5 0 0 1 1.5 1.5v.5a.5.5 0 1 1-1 0v-.5a.5.5 0 0 0-.5-.5H13a5 5 0 0 1-10 0h-.5a.5.5 0 0 0-.5.5v.5a.5.5 0 1 1-1 0v-.5A1.5 1.5 0 0 1 2.5 10H3V9H1.5a.5.5 0 0 1 0-1H3V7h-.5A1.5 1.5 0 0 1 1 5.5V5a.5.5 0 0 1 1 0v.5a.5.5 0 0 0 .5.5H3c0-1.364.547-2.601 1.432-3.503l-.41-1.352a.5.5 0 0 1 .333-.623zM4 7v4a4 4 0 0 0 3.5 3.97V7H4zm4.5 0v7.97A4 4 0 0 0 12 11V7H8.5zM12 6H4a3.99 3.99 0 0 1 1.333-2.982A3.983 3.983 0 0 1 8 2c1.025 0 1.959.385 2.666 1.018A3.989 3.989 0 0 1 12 6z"
                                    />
                                </svg>
                &nbsp; Error :
              </span>
                            &nbsp;
                              {Object.values(LoginFormError).map((Element, Index) => {
                                return (
                                  <span key={Index}>
                                    { Element}
                                    <br />
                                  </span>
                                );
                              })}
                        </div>
                    ) : null}
                    {LoginFormSuccess ? (
                        <div className="ErrorsModule">
                            <span className="text-muted">
                                <svg
                                    width="1.3em"
                                    height="1.3em"
                                    style={{ marginTop: "-3px" }}
                                    viewBox="0 0 16 16"
                                    className="bi bi-check-circle"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                    />
                                    <path
                                        fillRule="evenodd"
                                        d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                                    />
                                </svg>{" "}
                &nbsp; Successfully Login.
              </span>
                        </div>
                    ) : null}

                </form>
            </div>
        );
  }
}

export default LoginForm;
