import React, { Component } from "react";
import "../styles/style.scss";
import axios from "../hoc/axios";
import Header from "../components/Panel/Header";
import { DELIVERY_ADD, POST_METHOD } from "../constants/apis";
import ReactFormInputValidation from "react-form-input-validation";
import { Route } from "react-router";
import img_1 from "../assets/img-1.jpg";
import img_2 from "../assets/img-2.jpg";
import img_3 from "../assets/img-3.jpg";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Geocode from "react-geocode";
import { Link } from "react-router-dom";
import DragComponent from "../hoc/dragComponent";
import { connect } from "react-redux";
import { ADD_PROGRESS } from "../store/actions/LoaderActions";
import Classes from "../styles/MyStore/MyStore.module.css";

export class Chart extends Component {

   state = {
        amount: "45.20",
        customerLocation:"",
        storeLocation:"",
        customerLat:"",
        customerLng:"",
        storeLat:"",
        storeLng:""
   };

   

  render() {
        Geocode.setApiKey("AIzaSyD4doZrX7pSltqAe8dRnZBcG2N6Ig8QMeU");
  
        Geocode.setLanguage("en");

        this.form = new ReactFormInputValidation(this);

        this.form.onformsubmit = (fields) => {
            const data = this.state;

            // axios(
            //     POST_METHOD,
            //     DELIVERY_ADD,
            //     data,
            //     this.props.setLoadingProgress
            // ).then((Response) => {
            //     if(Response.success){
            //         window.location.href = "/driver-availability?add="+Response.data;
            //     }
            // });
            localStorage.setItem("delivery_data", JSON.stringify(data));
            window.location.href = "/driver-availability";
            console.log(this.state);
        };

        var setCustomerLocation = (event) => {
            Geocode.fromAddress(event.value.description).then(
              response => {
                const { lat, lng } = response.results[0].geometry.location;
                this.setState({customerLat: lat});
                this.setState({customerLng: lng});
                
              },
              error => {
                console.error(error);
              }
            );
            this.setState({customerLocation: event.value.description});
            
        };

        var setStoreLocation = (event) => {
            Geocode.fromAddress(event.value.description).then(
              response => {
                const { lat, lng } = response.results[0].geometry.location;
                this.setState({storeLat: lat});
                this.setState({storeLng: lng});
                
              },
              error => {
                console.error(error);
              }
            );
            this.setState({storeLocation: event.value.description});
        };

        const LinksList = [
          {
            name: "Cart",
            link: "/cart",
          },
        ];

      return (
        <section className={Classes.Parent}>
            <Header LinksList={LinksList} />
            <section className="main-section">
               

                <section className="chart-section">
                    <div className="inner-wrapper">
                        <div className="inner-content-cart">
                            <div className="heading">Cart</div>
                                <div className="item-row">
                                    <div className="item-image">
                                        <img src={img_1} />
                                    </div>
                                    <div className="item-description">
                                        <h3>Item Name Here</h3>
                                        <p>Partial description goes here</p>
                                        <p>Store Name Here</p>
                                    </div>
                                    <div className="item-price">
                                        <div className="item-control">
                                            <p>$10.00</p>
                                            <div className="trigger">
                                                <a href="#" className="up"></a>
                                                <div className="items-number">1</div>
                                                <a href="#" className="up"></a>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="item-row">
                                    <div className="item-image">
                                        <img src={img_2} />
                                    </div>
                                    <div className="item-description">
                                        <h3>Item Name Here</h3>
                                        <p>Partial description goes here</p>
                                        <p>Store Name Here</p>
                                    </div>
                                    <div className="item-price">
                                        <div className="item-control">
                                            <p>$10.00</p>
                                            <div className="trigger">
                                                <a href="#" className="up"></a>
                                                <div className="items-number">1</div>
                                                <a href="#" className="up"></a>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="item-row">
                                    <div className="item-image">
                                        <img src={img_3} />
                                    </div>
                                    <div className="item-description">
                                        <h3>Item Name Here</h3>
                                        <p>Partial description goes here</p>
                                        <p>Store Name Here</p>
                                    </div>
                                    <div className="item-price">
                                        <div className="item-control">
                                            <p>$10.00</p>
                                            <div className="trigger">
                                                <a href="#" className="up"></a>
                                                <div className="items-number">1</div>
                                                <a href="#" className="up"></a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                
                                <div className="tl-main-row">
                                    <div className="tl-row">
                                        <div className="two-col s-row">
                                            <div className="col-1 col">
                                                <h3>Total (<span>3</span> items)</h3>
                                            </div>
                                            <div className="col-2 col">$30.00</div>
                                        </div>
                                    </div>

                                    <div className="tl-row">
                                        <div className="two-col">
                                            <div className="col-1 col">
                                                <p>Delivery Charges</p>
                                            </div>
                                            <div className="col-2 col">$10.00</div>
                                        </div>
                                    </div>

                                    <div className="tl-row">
                                        <div className="two-col">
                                            <div className="col-1 col">
                                                <p>Tax</p> 
                                            </div>
                                            <div className="col-2 col">$5.20</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="pm-row">
                                    <h2>Payble amount:<span>$45.20</span></h2>
                                </div>
                                <form className="form-nav" onSubmit={this.form.handleSubmit}>
                                    
                                    <div className="form-row">
                                        <div className="form-control-av">
                                            <GooglePlacesAutocomplete apiKey="AIzaSyD4doZrX7pSltqAe8dRnZBcG2N6Ig8QMeU" 
                                             selectProps={{
        onChange: setCustomerLocation,
      }} />
                                            <label>Customer Location</label>
                                        </div>
                                        <div className="form-control-av">
                                            <GooglePlacesAutocomplete apiKey="AIzaSyD4doZrX7pSltqAe8dRnZBcG2N6Ig8QMeU"   selectProps={{
       
        onChange: setStoreLocation,
      }}/>
                                            <label>Store Location</label>
                                        </div>
                                    </div>
                                
                                
                                    <div className="form-row">
                                        <div className="form-control-av">
                                            <button className="ch-btn" >Check Delivery availablity</button>
                                        </div>
                                        <div className="form-control-av">
                                            <button className="ch-btn">Store Pickup<span>(No Delivery Needed)</span></button>
                                        </div>
                                    </div>
                                </form>
                        </div>
                    </div>
                </section>
            </section>
        </section>
      )
  }
}

const dispatchToProps = (dispatch) => {
  return {
    setLoadingProgress: (progress) => {
      //dispatch({ type: ADD_PROGRESS, progress });
    },
  };
};
export default connect(dispatchToProps) (Chart);
