import React, { Component } from "react";
import Classes from "../../../styles/MyStore/MyStore.module.css";
import StoreRegistrationForm from "../../StoreRegistrationForm";
import Header from "../Header";
import { connect } from "react-redux";
import { VENDOR_STORE, POST_METHOD } from "../../../constants/apis";
import axios from "../../../hoc/axios";
import DisplayStore from "./DisplayStore";
import { ADD_PROGRESS } from "../../../store/actions/LoaderActions";

// import axios from "../hoc/axios";

export class StoreEdit extends Component {
  state = {};
  render() {
    //REGISTER STORE
    const registerStore = (state, isCreated = false) => {
      this.setState({
        StoreRegistrationSuccess: null,
        StoreRegistrationSuccess2: null,
        StoreRegistrationError: null,
      });

      const data = new FormData();
      if (state.selectedFile) {
        data.append("store_image", state.selectedFile, state.selectedFile.name);
      }
      data.append("store_id", state.fields.store_id);
      data.append("store_name", state.fields.store_name);
      data.append("address", state.fields.address);
      data.append("city", state.fields.city);
      data.append("province", state.fields.province);
      data.append("nation", state.fields.nation);
      data.append("store_type", state.fields.store_type);

      axios(
        POST_METHOD,
        VENDOR_STORE,
        data,
        this.props.setLoadingProgress
      ).then((Response) => {
        if (Response.success) {
          if (state.redirect) {
            window.location.href = "/Delivery";
          }
          if (isCreated) {
            window.location.href = "/MyStore?status=done";
          } else {
            window.location.href = "/MyStore";
          }
          return this.setState({ StoreRegistrationSuccess2: true });
        } else {
          this.setState({
            StoreRegistrationError: Response.message,
          });
        }
      });
    };

    const LinksList = [
      {
        name: "My Store",
        link: "/MyStore",
      },
    ];

    return (
      <section className={Classes.Parent}>
        <Header LinksList={LinksList} />
        <div className={Classes.form}>
          <StoreRegistrationForm
            {...this.props}
            {...this.state}
            displayAlert={true}
            registerStore={registerStore}
          />
        </div>
      </section>
    );
  }
}
const stateToProps = (state) => {
  return {
    name: state.user.first_name + " " + state.user.last_name,
    store_created: state.user.store_created,
  };
};

const dispatchToProps = (dispatch) => {
  return {
    setLoadingProgress: (progress) => {
      dispatch({ type: ADD_PROGRESS, progress });
    },
  };
};

export default connect(stateToProps, dispatchToProps)(StoreEdit);
