import React, { Component } from "react";
import Logo from '../assets/logo.png'
import Hawaiian from "../assets/hawaiian.png"
import cafe1 from '../assets/cafe-1.jpg'
import Slider from "react-slick";
import queryString from 'query-string'
import axios from "../hoc/axios"
import UserIcon from '../assets/icon_role_user.jpg'
import { ITEMINFO, GET_METHOD, STORE } from "../constants/apis"
import { connect } from "react-redux"
import { ADD_PROGRESS } from "../store/actions/LoaderActions"
import Cookies from "universal-cookie"

// import  "./FoodSearch.scss"

export class ProductDescription extends Component {

    state = {
        search:'',
        data:[],
        store:[],
        success: false
    };

    componentDidMount() {
        var url = window.location.pathname;
        var id = url.substring(url.lastIndexOf('/')+1);
         axios(
            GET_METHOD,
            ITEMINFO+"/"+id,
            {},
            this.props.setLoadingProgress
            ).then((Response) => {
                if (Response.success===true) {
                    this.setState({ 
                        data: Response.data.data,
                        success:true
                    });

                    axios(
                        GET_METHOD,
                        STORE+"/"+Response.data.data.store_id,
                        {},
                        this.props.setLoadingProgress
                        ).then((store) => {
                            if (store.success===true) {
                                this.setState({ 
                                    store: store.data.data,
                                });
                            }
                    });
                }
        });
    }
    render(){
        const cookie = new Cookies();
        var authenticated = cookie.get("MEKey") ? true : false;

        var settings = {
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
          };
        return (
            <div>
                
                <div className="main-section-wrapper">
                <section className="layer-container">
                    <div className="navigation">
                        <div className="logo">
                            <a href="/"><img src={Logo} alt=""/></a>
                        </div>
                        <div className="btn-row">
                             {authenticated===false?<div className="btn-row"><a href="/Login" className="btn">Sign In</a></div>:<div><a href="/Settings" ><img style={{ height: '56px', width: '56px'}} scr={UserIcon} /></a></div>}
                        </div>
                    </div>
                    <div className="banner-row">
                        <div className="banner">
                        <Slider {...settings}>
                            {this.state.data.image!==""?<img src={this.state.data.image} alt="" srcSet=""/>:''}
                            {this.state.data.preview1!==""?<img src={this.state.data.preview1} alt="" srcSet=""/>:''}
                            {this.state.data.preview2!==""?<img src={this.state.data.preview2} alt="" srcSet=""/>:''}
                            {this.state.data.preview3!==""?<img src={this.state.data.preview3} alt="" srcSet=""/>:''}
                            {this.state.data.preview4!==""?<img src={this.state.data.preview4} alt="" srcSet=""/>:''}
                            
                        </Slider>
                        </div>
                        <div className="price-row">
                            <div className="title"><p>{this.state.data.name}</p></div>
                            <div className="full-row">
                                <div className="half-col"><p>$17.00</p></div>
                                <div className="half-col"><p>3.5</p>
                                    <span className="full-star"></span>
                                    <span className="full-star"></span>
                                    <span className="full-star"></span>
                                    <span className="full-star-half"></span>
                                    <span className="full-star-blank"></span><p>(1,035)</p>
                                </div>
                            </div>
                            <div className="ful-row mt-3">
                                <p>{this.state.data.description}</p>
                                <a href="#" className="btn btn-solid">Add to Cart</a>
                            </div>
                            <br />
                            <br />
                            <hr/>
                            <div className="store__location product-description">
                                <div className="store__image">
                                    <img src={this.state.store.store_image} alt=""/>
                                </div>
                                <div className="store-location-detail">
                                    <p>{this.state.store.store_name}</p>
                                    <p className="address">
                                        {this.state.store.address}<br />
                                        {this.state.store.city} {this.state.store.province} {this.state.store.nation}
                                    </p>
                                    <div className="store__distance">4.7km</div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            </div>
        )

    }
}

const dispatchToProps = (dispatch) => {
  return {
    setLoadingProgress: (progress) => {
      
    },
  };
};


export default connect(dispatchToProps) (ProductDescription);
