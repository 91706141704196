import React, { Component } from "react"
import $ from 'jquery'
import Classes from "../styles/MyStore/MyStore.module.css"
import Logo from '../assets/logo.png'
import Mac from '../assets/mac-banner.jpg'
import UserIcon from '../assets/icon_role_user.jpg'
import axios from "../hoc/axios";
import { HOME, GET_METHOD } from "../constants/apis"
import Hawaiian from "../assets/hawaiian.png"
import  "./searchComponent/FoodSearch.scss"
import { connect } from "react-redux"
import { ADD_PROGRESS } from "../store/actions/LoaderActions"
import { useParams } from "react-router";
import Cookies from "universal-cookie";
import 'font-awesome/css/font-awesome.min.css';
import NavBar from "./Panel/NavBar";


export class Main extends Component {
	state = {
		categories:[],
		banners:[],
		stores:[],
		products:[],
		search:'',
		success:false,
		menu:false
	};

	componentDidMount() {
	    axios(
	      	GET_METHOD,
	      	HOME,
	      	{},
	      	this.props.setLoadingProgress
	    ).then((Response) => {
	      	if (Response.success) {
	        	this.setState({ 
	        		categories: Response.data.data.categories,
	        		banners: Response.data.data.banners,
	        		stores: Response.data.data.stores,
	        		products: Response.data.data.products,
	        		success: true
	        	});
	      	}
	    });
	}

	render() {

		const cookie = new Cookies();
		var authenticated = cookie.get("MEKey") ? true : false;

		const clickHandler = (slug) => {
	        window.location.href = "/search/?category="+slug;
	    }

	    const storeClickHandler = (slug) => {
	        window.location.href = "/storeSearch/?store="+slug;
	    }

	    const changeHandler = (e) => {
	    	this.setState({ search: e.target.value });
	    }

	    const submitHandler = (e) => {
	    	e.preventDefault();
	    	window.location.href = "search/?item="+this.state.search;
	    }

	    const productClickHandler = (id) => {

            window.location.href = '/products/'+id
        }

        
        const clickEventHandler = (menu) => {
        	this.setState({
        		menu: menu
        	})
        }

        $(".bm-cross-button").click(function(){
        	$(".navigation").removeClass("visible");
        });
        
		return (
		        <div className="main-section-wrapper">
	            <section className="layer-container">
	                <div className={this.state.menu===true?"navigation visible":"navigation"} >
	                	{authenticated===true?<NavBar onClickMenu={clickEventHandler} />:<div className="logo"><a href="/"><img src={Logo} alt=""/></a></div>}
	                    
	                    {authenticated===false?<div className="btn-row"><a href="/Login" className="btn">Sign In</a></div>:<div><a href="/Settings" ><i className="fa fa-users fa-2x" style={{  color: '#000', padding: '20px'}} aria-hidden="true"></i></a></div>}
	                </div>

	                <div className="inner-content">
	                    <h2>Food Search</h2>
	                    <br />
	                    <p>What kind food are you looking for ?</p>

	                    <form action="" className="search-food-form">
	                        <input type="text" placeholder="Food Name Here" value={this.state.search} onChange={changeHandler} />
	                        <button type="submit" onClick={submitHandler} className="btn">Search Now</button>
	                    </form>

	                    <div className="tag-row">
	                        <div className="tagline"><hr /><p>ONE CLICK FOOD SEARCH</p><hr /></div>
	                    </div>
	                    <div className="tags">
	                        { this.state.categories.map((category) => {
	                        	return (<a href="#" key={category.id} onClick={()=>clickHandler(category.id)}>{category.name}</a>)
	                        })}
	                        
	                       
	                    </div>
	                    <div className="tag-row">
	                        <div className="tagline"><hr /><p>RESTARANT SEARCH</p><hr /></div>
	                    </div>
	                    <div className="tags">
	                    	{ this.state.stores.map((store) => {
	                        	return (<a href="#" key={store.id} onClick={()=>storeClickHandler(store.name)}>{store.name}</a>)
	                        })}
	          
	                    </div>
	                    <div className="tag-row">
	                        <div className="tagline"><hr /><p>SUPERMARKET ITEMS</p><hr /></div>
	                    </div>
	                    <div className="tags" style={{ display: "block" }}>
	                        <a href="/supermarkets" className="btn btn-light">Supermarket item Delivery Request</a>
	                    </div>
	                </div>
	                    <div className="banner">
	                        <img src={Mac} style={{ width: "100%" }} alt="" srcSet=""/>
	                    </div>

	                    { this.state.products.map((product) => {
	                    	return (<div key={product.id} onClick={() => productClickHandler(product.id)} style={{ 'cursor':'pointer' }}>
			                    <div className="banner">
			                        <img src={product.image} style={{ width: "100%", height:"300px"}} alt="" srcSet=""/>
			                    </div>
			                    <div className="price-row">
			                        <div className="title"><p>{product.name}</p></div>
			                        <div className="full-row">
			                            <div className="half-col"><p>${product.price}</p></div>
			                            <div className="half-col"><p>3.5</p>
			                                <span className="full-star"></span>
			                                <span className="full-star"></span>
			                                <span className="full-star"></span>
			                                <span className="full-star-half"></span>
			                                <span className="full-star-blank"></span><p>(1,035)</p>
			                            </div>
			                        </div>
			                    </div>
		                    </div>)
		                })}
	                    
	            </section>
	        </div>
		);
	}

}

const dispatchToProps = (dispatch) => {
  return {
    setLoadingProgress: (progress) => {
      
    },
  };
};


export default connect(dispatchToProps) (Main);