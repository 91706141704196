/*============================================================================================
                                    STORE REGISTRATION COMPONENT
===============================================================================================*/
import React, { Component } from "react";
import Classes from "../styles/StoreRegistrationForm.module.css";
import ReactFormInputValidation from "react-form-input-validation";
import axios from "../hoc/axios";
import qs from "query-string";
import { VENDOR_STORE, GET_METHOD } from "../constants/apis";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ADD_PROGRESS } from "../store/actions/LoaderActions";

class StoreRegistrationForm extends Component {
  state = {
    selectedFile: "",
    fields: {
      store_image: "",
      store_name: "",
      address: "",
      store_id: "",
      city: "",
      province: "",
      nation: "",
      store_type: "",
    },
    file: null,
    redirect: false,
    errors: {},
  };
  componentDidMount() {
    if (this.props.store_created) {
      axios(GET_METHOD, VENDOR_STORE, {}, this.props.setLoadingProgress).then(
        (Response) => {
          if (Response.success) {
            this.setState({
              fields: { ...this.state.fields, ...Response.data.data },
            });
          }
        }
      );
    }
  }
  render() {
    const parsed = qs.parse(this.props.location.search);
    const {
      StoreRegistrationError,
      StoreRegistrationSuccess,
      StoreRegistrationSuccess2,
      name,
      store_created,
      displayAlert,
    } = this.props;
    /*============================================================================================
                                    FUNCTIONS
===============================================================================================*/
    this.form = new ReactFormInputValidation(this);

    this.form.useRules({
      store_name: "required",
      address: "required",
      city: "required",
      province: "required",
      nation: "required",
      store_id: "required",
      store_type: "required",
    });

    this.form.onformsubmit = (fields) => {
      this.props.registerStore(this.state, !store_created);
    };

    var onChangeHandler = (event) => {
      this.setState({
        selectedFile: event.target.files[0],
      });

      this.setState({
        file: URL.createObjectURL(event.target.files[0]),
      });
    };

    var onSubmitNext = (event) => {
      this.setState({ redirect: true });
    };

    /*============================================================================================
                                    JSX
===============================================================================================*/
    return (
      <div
        className={
          Classes.Parent + " animate__animated animate__fadeIn animate__slow"
        }
      >
        <div className="row">
          <div className="col-12">
            <form onSubmit={this.form.handleSubmit}>
              <div
                className={`InputDialog col-6 offset-3 ${Classes.FileUpload}`}
              >
                <center>
                  <img
                    src={
                      this.state.file == null
                        ? this.state.fields.store_image
                          ? this.state.fields.store_image
                          : "/coming-soon-store-placeholder-image.gif"
                        : this.state.file
                    }
                    style={{ width: "146px" }}
                  />
                  
                  <input
                    type="file"
                    onChange={(event) => {
                      onChangeHandler(event);
                    }}
                  />
                </center>
               
              </div>
              <div className="form-group row login-form-custom">
                <div className="col-6 text-right col-md-5">
                  <div className={Classes.Element}>
                    <label for="store_type">
                      Store Type <span className="Asterisk">*</span> :{" "}
                    </label>
                  </div>
                </div>
                <div className="col-6 text-left">
                  <div>
                    <select
                      id="store_type"
                      name="store_type"
                      value={this.state.fields.store_type}
                      onChange={this.form.handleChangeEvent}
                      className={
                        this.state.errors.store_type ? "ErrorInput" : null
                      }
                    >
                      <option value="">Store Type</option>
                      <option value="Supermarket">Supermarket</option>
                      <option value="Chinese Restaurant">
                        Chinese Restaurant
                      </option>
                      <option value="Japanese Restaurant">
                        Japanese Restaurant
                      </option>
                      <option value="Italian Restaurant">
                        Italian Restaurant
                      </option>
                      <option value="Mediterian Restaurant">
                        Mediterian Restaurant
                      </option>
                      <option value="Bakery">Bakery</option>
                      <option value="Cafe">Cafe</option>
                    </select>
                    <label className="error">
                      {this.state.errors.store_type
                        ? this.state.errors.store_type
                        : ""}
                    </label>
                  </div>
                </div>
              </div>

              <div className="form-group row login-form-custom">
                <div className="col-6 text-right col-md-5">
                  <div className={Classes.Element}>
                    <label for="store_name">
                      Store Name <span className="Asterisk">*</span> :{" "}
                    </label>
                  </div>
                </div>
                <div className="col-6 text-left">
                  <div>
                    <input
                      type="text"
                      placeholder="Store Name *: "
                      name="store_name"
                      id="store_name"
                      data-attribute-name="Store Name"
                      className={
                        this.state.errors.store_name ? "ErrorInput" : null
                      }
                      onChange={this.form.handleChangeEvent}
                      value={this.state.fields.store_name}
                    />
                    <label className="error">
                      {this.state.errors.store_name
                        ? this.state.errors.store_name
                        : ""}
                    </label>
                  </div>
                </div>
              </div>

              <div className="form-group row login-form-custom">
                <div className="col-6 text-right col-md-5">
                  <div className={Classes.Element}>
                    <label for="store_id">
                      Store ID <span className="Asterisk">*</span>:{" "}
                    </label>
                  </div>
                </div>
                <div className="col-6 text-left">
                  <div>
                    <input
                      type="text"
                      placeholder="Unique Store ID *: "
                      name="store_id"
                      id="store_id"
                      data-attribute-name="Unique Store ID"
                      className={
                        this.state.errors.store_id ? "ErrorInput" : null
                      }
                      onChange={this.form.handleChangeEvent}
                      value={this.state.fields.store_id}
                    />
                    <label className="error">
                      {this.state.errors.store_id
                        ? this.state.errors.store_id
                        : ""}
                    </label>
                  </div>
                </div>
              </div>

              <div className="form-group row login-form-custom">
                <div className="col-6 text-right col-md-5">
                  <div className={Classes.Element}>
                    <label for="address">
                      Address <span className="Asterisk">*</span> :{" "}
                    </label>
                  </div>
                </div>
                <div className="col-6 text-left">
                  <div>
                    <input
                      type="text"
                      placeholder="Address *: "
                      name="address"
                      id="address"
                      className={
                        this.state.errors.address ? "ErrorInput" : null
                      }
                      onChange={this.form.handleChangeEvent}
                      value={this.state.fields.address}
                    />
                    <label className="error">
                      {this.state.errors.address
                        ? this.state.errors.address
                        : ""}
                    </label>
                  </div>
                </div>
              </div>

              <div className="form-group row login-form-custom">
                <div className="col-6 text-right col-md-5">
                  <label for="city">
                    City <span className="Asterisk">*</span> :
                  </label>
                </div>
                <div className="col-6 text-left">
                  <div>
                    <input
                      type="text"
                      placeholder="City *"
                      name="city"
                      id="city"
                      className={this.state.errors.city ? "ErrorInput" : null}
                      onChange={this.form.handleChangeEvent}
                      value={this.state.fields.city}
                    />
                    <label className="error">
                      {this.state.errors.city ? this.state.errors.city : ""}
                    </label>
                  </div>
                </div>
              </div>

              <div className="form-group row login-form-custom">
                <div className="col-6 text-right col-md-5">
                  <div className={Classes.Element}>
                    <label for="province">
                      Province <span className="Asterisk">*</span> :{" "}
                    </label>
                  </div>
                </div>
                <div className="col-6 text-left">
                  <div>
                    <input
                      type="text"
                      placeholder="Province *"
                      name="province"
                      id="province"
                      className={
                        this.state.errors.province ? "ErrorInput" : null
                      }
                      onChange={this.form.handleChangeEvent}
                      value={this.state.fields.province}
                    />
                    <label className="error">
                      {this.state.errors.province
                        ? this.state.errors.province
                        : ""}
                    </label>
                  </div>
                </div>
              </div>

              <div className="form-group row login-form-custom">
                <div className="col-6 text-right col-md-5">
                  <div className={Classes.Element}>
                    <label for="nation">
                      Country <span className="Asterisk">*</span> :{" "}
                    </label>
                  </div>
                </div>
                <div className="col-6 text-left">
                  <div>
                    <select
                      id="nation"
                      name="nation"
                      value={this.state.fields.nation}
                      onChange={this.form.handleChangeEvent}
                      className={this.state.errors.nation ? "ErrorInput" : null}
                    >
                      <option value="">Select Nation</option>
                      <option value="Canada">Canada</option>
                      <option value="USA">USA</option>
                    </select>
                    <label className="error">
                      {this.state.errors.nation ? this.state.errors.nation : ""}
                    </label>
                  </div>
                </div>
              </div>

              {StoreRegistrationError ? (
                <div className="row">
                  <div className="ErrorsModule">
                    <span className="text-muted">
                      <svg
                        width="1.3em"
                        height="1.3em"
                        style={{ marginTop: "-3px" }}
                        viewBox="0 0 16 16"
                        className="bi bi-bug"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.355.522a.5.5 0 0 1 .623.333l.291.956A4.979 4.979 0 0 1 8 1c1.007 0 1.946.298 2.731.811l.29-.956a.5.5 0 1 1 .957.29l-.41 1.352A4.985 4.985 0 0 1 13 6h.5a.5.5 0 0 0 .5-.5V5a.5.5 0 0 1 1 0v.5A1.5 1.5 0 0 1 13.5 7H13v1h1.5a.5.5 0 0 1 0 1H13v1h.5a1.5 1.5 0 0 1 1.5 1.5v.5a.5.5 0 1 1-1 0v-.5a.5.5 0 0 0-.5-.5H13a5 5 0 0 1-10 0h-.5a.5.5 0 0 0-.5.5v.5a.5.5 0 1 1-1 0v-.5A1.5 1.5 0 0 1 2.5 10H3V9H1.5a.5.5 0 0 1 0-1H3V7h-.5A1.5 1.5 0 0 1 1 5.5V5a.5.5 0 0 1 1 0v.5a.5.5 0 0 0 .5.5H3c0-1.364.547-2.601 1.432-3.503l-.41-1.352a.5.5 0 0 1 .333-.623zM4 7v4a4 4 0 0 0 3.5 3.97V7H4zm4.5 0v7.97A4 4 0 0 0 12 11V7H8.5zM12 6H4a3.99 3.99 0 0 1 1.333-2.982A3.983 3.983 0 0 1 8 2c1.025 0 1.959.385 2.666 1.018A3.989 3.989 0 0 1 12 6z"
                        />
                      </svg>
                      &nbsp; Please fix the following issues :
                    </span>
                    <br />
                    {Object.values(StoreRegistrationError).map(
                      (Element, Index) => {
                        return (
                          <span key={Index}>
                            {Index + 1 + " " + Element}
                            <br />
                          </span>
                        );
                      }
                    )}
                  </div>
                </div>
              ) : null}

              {StoreRegistrationSuccess2 ? (
                <div className="row">
                  <div className="ErrorsModule">
                    <span className="text-muted">
                      <svg
                        width="1.3em"
                        height="1.3em"
                        style={{ marginTop: "-3px" }}
                        viewBox="0 0 16 16"
                        className="bi bi-check-circle"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                        />
                        <path
                          fillRule="evenodd"
                          d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                        />
                      </svg>{" "}
                      &nbsp; {store_created ? "Store updated successfully" : ""}
                      .
                    </span>
                  </div>
                </div>
              ) : null}

              <div className="row form_actions" style={{ marginTop: "10px" }}>
                {!StoreRegistrationSuccess && !store_created ? (
                  <>
                    {/* <Link className="button" to="/profile">
                  {" "}
                  Back
                </Link>
                &nbsp;
                <Link className="button" to="/Delivery">
                  {" "}
                  Next
                </Link> */}
                    &nbsp;
                    <div className="col-6 text-center">
                      <button>Save</button>
                    </div>
                    <div className="col-5 text-center">
                      {/* <button className="sm" onClick={onSubmitNext}>
                  {" "}
                  Save & Next
                </button> */}
                      <Link className="button" to="/Dashboard">
                        Go to Main
                      </Link>
                    </div>
                  </>
                ) : null}

                {store_created ? (
                  <>
                    <div className="col-6">
                      <button> Update</button>&nbsp;
                    </div>
                    <div className="col-6">
                      <Link className="button" to="/MyStore">
                        {" "}
                        Cancel
                      </Link>
                    </div>
                  </>
                ) : null}
                <br />
                <br />
                {/* {StoreRegistrationSuccess ? (
            <>
              
            </>
          ) : null} */}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const dispatchToProps = (dispatch) => {
  return {
    setLoadingProgress: (progress) => {
      dispatch({ type: ADD_PROGRESS, progress });
    },
  };
};

export default connect(null, dispatchToProps)(StoreRegistrationForm);
