import React, { Component } from "react";
import Classes from "../styles/MyItems/MyItems.module.css";
import Datatable from "../hoc/datatable";
import Header from "../components/Panel/Header";
import { connect } from "react-redux";
import axios from "../hoc/axios";
import { Route } from "react-router";
import {
  CREATE_GROUP,
  GET_ALL_GROUPS,
  GET_METHOD,
  POST_METHOD,
} from "../constants/apis";
import { Link } from "react-router-dom";
import DragComponent from "../hoc/dragComponent";
import EditGroup from "../components/Panel/MyGroups/EditGroup";
import { ADD_PROGRESS } from "../store/actions/LoaderActions";

export class MyGroups extends Component {
  state = {
    AddItemError: "",
    AddItemSuccess: "",
    data: {
      columns: [
        {
          label: "ID",
          field: "id",
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "Name",
          field: "name",
          width: 270,
        },
      ],
      rows: [],
    },
  };

  componentDidMount() {
    axios(
      GET_METHOD,
      GET_ALL_GROUPS + this.props.store_id,
      {},
      this.props.setLoadingProgress
    ).then((Response) => {
      const { success } = Response;
      if (success) {
        const data = [];
        /*eslint-disable-next-line*/
        Response.data.data.map((Element) => {
          data.push({
            ...Element,
             link: "View",
            clickEvent: () => (window.location.href = `/MyGroups/Edit?id=${Element.id}`), 
          });
        });

        this.setState({
          data: { columns: [...this.state.data.columns], rows: data },
        });
      }
    });
  }

  render() {
    const RefreshData = () => {
      this.setState({
        data: { columns: [...this.state.data.columns], row: [] },
      });
      axios(
        GET_METHOD,
        GET_ALL_GROUPS + this.props.store_id,
        {},
        this.props.setLoadingProgress
      ).then((Response) => {
        if (Response.success) {
          const data = [];
          /*eslint-disable-next-line*/
          Response.data.data.map((Element) => {
            data.push({
              ...Element,
               link: "View",
              clickEvent: () =>
                (window.location.href = `/MyGroups/Edit?id=${Element.id}`), 
            });
          });
          this.setState({
            data: {
              columns: [...this.state.data.columns],
              rows: data,
            },
          });
        }
      });
    };

    const LinksList = [
      {
        name: "My Categories",
        link: "/MyGroups",
      },
    ];
    const submitAddGroup = () => {
      if (this.state.groupName) {
        axios(
          POST_METHOD,
          CREATE_GROUP,
          {
            name: this.state.groupName,
            store_id: this.props.store_id,
          },
          this.props.setLoadingProgress
        ).then((Response) => {
          RefreshData();
        });
      }
    };
    return (
      <section className={Classes.Parent}>
        <Route path="/MyGroups" exact>
          <Header LinksList={LinksList} />
          
          <div
            style={{ marginTop: "-20px", padding: "40px", textAlign:"center", paddingBottom: "0px", paddingTop: "0px" }}
          >
            <div className="InputDialog">
              <input
                type="text"
                className={this.state.groupName ? null : "ErrorInput"}
                placeholder="Please enter Category Name to Add"
                onChange={(event) => {
                  this.setState({ groupName: event.target.value });
                }}
                style={{ padding:"10px" }}
              />
              <br />
            </div>
            <button
              onClick={() => {
                submitAddGroup();
              }}
            >
              Add Category
            </button>
          </div>
          <section className={Classes.Child}>
            <Datatable datatable={this.state.data} />
          </section>
        </Route>
        <Route path="/MyGroups/Edit">
          <div className={Classes.Child}>
            <br />
            <Link to="/MyGroups">
              <svg
                style={{ color: "#f05a28" }}
                width="2em"
                height="2em"
                viewBox="0 0 16 16"
                className="bi bi-arrow-left-square-fill"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.354 10.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L6.207 7.5H11a.5.5 0 0 1 0 1H6.207l2.147 2.146z"
                />
              </svg>
            </Link>
            <div style={{ color: "#f05a28", cursor: "pointer" }}>
              <br />
              <EditGroup {...this.props} />
            </div>
            { /*<p className="text-muted my-10" style={{ padding: "5px 40px" }}>
              Drag items below to sort.
            </p> */}
            <div className="item-contailer">
              <DragComponent {...this.props} />
            </div>
          </div>
        </Route>
      </section>
    );
  }
}
const stateToProps = (state) => {
  return {
    name: state.user.first_name + " " + state.user.last_name,
    store_created: state.user.store_created,
    store_id: state.user.store_id,
    cat_created: state.user.cat_created,
  };
};
const dispatchToProps = (dispatch) => {
  return {
    setLoadingProgress: (progress) => {
      dispatch({ type: ADD_PROGRESS, progress });
    },
  };
};
export default connect(stateToProps, dispatchToProps)(MyGroups);
