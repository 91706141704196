import React, { Component } from "react";
import Classes from "../../../styles/MyItems/ViewItem.module.css";
import DeletePopup from "../../../hoc/DeletePopup";
import axios from "../../../hoc/axios";
import { DELETE_ITEM, DELETE_METHOD } from "../../../constants/apis";
import { ADD_PROGRESS } from "../../../store/actions/LoaderActions";
import { connect } from "react-redux";

export class ViewItem extends Component {
  render() {
    const DeleteItem = (dummy) => {
      axios(
        DELETE_METHOD,
        DELETE_ITEM,
        { id: this.props.data.id },
        this.props.setLoadingProgress
      );
    };
    const {
      name,
      price,
      description,
      image,
      other_images,
      group_id,
      group_name,
    } = this.props.data;
    return (
      <div className={"row " + Classes.Parent}>
        <div style={{ cursor: "pointer" }}>
          <DeletePopup
            showText={true}
            Text={" Delete this Item"}
            ReloadPage={true}
            ReloadLink={"/Items"}
            deleteImageHandler={DeleteItem}
          />
        </div>

        <div className="col-md-12">
          Featured Photo :
          <br />
          <img src={image} alt={"FeaturedImage"} />
        </div>
        <div className="col-md-12">
          Other photos :
          <br />
          {other_images
            ? other_images.map((Element, Index) => {
                return (
                  <img src={Element.other_images} key={Index} alt={Element} />
                );
              })
            : null}
        </div>
        <div className="col-md-3">
          {" "}
          Name :<span>{name}</span>
        </div>

        <div className="col-md-3">
          {" "}
          Category :
          <span>
            <a href={"/MyGroups/Edit?id=" + group_id}>{group_name}</a>
          </span>
        </div>
        <div className="col-md-3">
          {" "}
          Price :<span>{price}</span>
        </div>
        <div className="col-md-6">
          {" "}
          Description :<span>{description}</span>
        </div>
      </div>
    );
  }
}

const dispatchToProps = (dispatch) => {
  return {
    setLoadingProgress: (progress) => {
      dispatch({ type: ADD_PROGRESS, progress });
    },
  };
};

export default connect(null, dispatchToProps)(ViewItem);
