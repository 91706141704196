//Axios - Function to make REST api calls
import axios from "axios";
import Cookies from "universal-cookie";
import {
  BASE_URL,
  GET_METHOD,
  POST_METHOD,
  DELETE_METHOD,
} from "../constants/apis";
import { ERROR_OCCURED } from "../constants/constants";

//Checks if Cookie exists or not
//If yes then get the token

const cookies = new Cookies();
var key = cookies.get("MEKey") ? cookies.get("MEKey") : "";
key = `Bearer ${key}`;
//Function to make API calls
const instance = async (Method, URL, data = {}, setLoadingProgress) => {
  setLoadingProgress(20);
  let json = {};
  //Handles API methods
  /*eslint-disable-next-line*/

  switch (Method) {
    //GET METHOD
    case GET_METHOD: {
      json = {
        method: GET_METHOD,
        url: BASE_URL + URL,
        headers: {
          Authorization: key,
        },
      };
      break;
    }

    //POST METHOD
    case POST_METHOD: {
      json = {
        method: POST_METHOD,
        url: BASE_URL + URL,
        data,
        headers: {
          Authorization: key,
        },
      };
      break;
    }

    //DELETE METHOD
    case DELETE_METHOD: {
      json = {
        method: DELETE_METHOD,
        url: BASE_URL + DELETE_METHOD,
        data: {
          data,
        },
        headers: {
          Authorization: key,
        },
      };
      break;
    }
  }
  setLoadingProgress(50);
  //Handles Response
  return await axios(json)
    .then((Response) => {
      if (Response.data.success) {
        setLoadingProgress(100);
        return { success: true, data: Response.data };
      } else {
        setLoadingProgress(100);
        return { success: false, message: Response.message };
      }
    })
    .catch((error) => {
      if (error.response) {
        var { message } = error.response.data;

        setLoadingProgress(100);
        return { success: false, message };
        // var { status} = error.response;
        // if (status === 401 || status === 403) {
        //   return console.log("Log me out.");
        // } else {
        //   return console.log(statusText);
        // }
      } else {
        setLoadingProgress(100);
        return { success: false, message: { message: ERROR_OCCURED } };
      }
    });
};

export default instance;
