import React, { Component } from "react"
import $ from 'jquery'
import Logo from '../../assets/logo.png'
import cafe1 from '../../assets/cafe-1.jpg'
import cafe2 from '../../assets/cafe-2.jpg'
import Hawaiian from "../../assets/hawaiian.png"
import qs from "query-string"
import axios from "../../hoc/axios"
import { STORE, GET_METHOD } from "../../constants/apis"
import "./StoreSearch.scss"
import { connect } from "react-redux"
import { ADD_PROGRESS } from "../../store/actions/LoaderActions"
import UserIcon from '../../assets/icon_role_user.jpg'
import Cookies from "universal-cookie"
import 'font-awesome/css/font-awesome.min.css';
import NavBar from "../Panel/NavBar";

export class StoreSearch extends Component {
    state = {
        data:[],
        success:false,
        menu:false
    };

render() {
    const parsed = qs.parse(this.props.location.search);

    const cookie = new Cookies();
    var authenticated = cookie.get("MEKey") ? true : false;

    const clickEventHandler = (menu) => {
        this.setState({
            menu: menu
        })
    }

    $(".bm-cross-button").click(function(){
        $(".navigation").removeClass("visible");
    });

    return (
        <div>
            <div className="main-section-wrapper" style={{ "background":"#e6e6e6bf" }}>
            <section className="layer-container">
                <div className="navigation">
                    {authenticated===true?<NavBar onClickMenu={clickEventHandler} />:<div className="logo"><a href="/"><img src={Logo} alt=""/></a></div>}
                        
                    {authenticated===false?<div className="btn-row"><a href="/Login" className="btn">Sign In</a></div>:<div><a href="/Settings" ><i className="fa fa-users fa-2x" style={{  color: '#000', padding: '20px'}} aria-hidden="true"></i></a></div>}
                </div>

                <div className="inner-content">
                    <h2>Search your {parsed.store} food</h2>

                    <form action="" autoComplete className="search-food-form food-search">
                        <select name="" id="">
                            <option>Chinese</option>
                            <option>Japanese</option>
                            <option>Italian</option>
                            <option>Indian</option>
                        </select>
                        <input type="text" placeholder="Food Name Here" />
                        <button type="submit" className="btn">Search Now</button>
                    </form>
                </div>
                <div className="store__location">
                    <div className="store__image">
                        <img src={cafe1} alt=""/>
                        <p>Pizza Pizza Richmond Hill</p>
                    </div>
                    <div className="store__distance">4.7km</div>
                </div>
                <div className="store__location">
                    <div className="store__image">
                        <img src={cafe2} alt=""/>
                        <p>Pizza Pizza Richmond Hill</p>
                    </div>
                    <div className="store__distance">4.7km</div>
                </div>
            </section>
        </div>
        </div>
        )
    }

}

const dispatchToProps = (dispatch) => {
  return {
    setLoadingProgress: (progress) => {
      
    },
  };
};


export default connect(dispatchToProps) (StoreSearch);
