import React, { Component } from 'react';
import './Form.css';
import Message from '../Message/Message';
import firebase from 'firebase';
import moment from "moment";

export default class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      User: '',
      Text: '',
      DateTime:'',
      list: [],
    };
    
    const user = JSON.parse(localStorage.getItem("user"));
    const driver = JSON.parse(localStorage.getItem("driver"));
    
    this.messageRef = firebase.database().ref('ChatLog').child('Customer'+user.id+'Driver'+driver.id);
    this.listenMessages();
  }
  componentWillReceiveProps(nextProps) {
    const user = JSON.parse(localStorage.getItem("user"));
    if(user) {
      this.setState({'User': user.id});
    }
  }
  handleChange(event) {
    this.setState({
      Text: event.target.value,
      DateTime: moment().format("YYYY-MM-DD hh:mm:ss")
    });
  }
  handleSend() {
    console.log(this.state);
    if (this.state.Text) {
      var newItem = {
        User: this.state.User,
        Text: this.state.Text,
        DateTime: this.state.DateTime,
      }
      this.messageRef.push(newItem);
      this.setState({ Text: '' });
      this.setState({ DateTime: '' });
    }
  }
  handleKeyPress(event) {
    if (event.key !== 'Enter') return;
    this.handleSend();
  }
  listenMessages() {
    this.messageRef
      .limitToLast(10)
      .on('value', message => {
        if(message.val()){
          this.setState({
            list: Object.values(message.val()),
          });
        }
      });
  }
  render() {
    return (
      <div className="form">
        <div className="form__message">
          { this.state.list.map((item, index) =>
            <Message key={index} message={item} driver={this.driver} user={this.user} />
          )}
        </div>
        <div className="form__row">
          <input
            className="form__input"
            type="text"
            placeholder="Type message"
            value={this.state.Text}
            onChange={this.handleChange.bind(this)}
            onKeyPress={this.handleKeyPress.bind(this)}
          />
          <button
            className="form__button"
            onClick={this.handleSend.bind(this)}
          >
            send
          </button>
        </div>
      </div>
    );
  }
}