/*============================================================================================
                                    STORE REGISTRATION COMPONENT
===============================================================================================*/
import React, { Component } from "react";
import Classes from "../../../styles/MyItems/AddItemForm.module.css";
import ReactFormInputValidation from "react-form-input-validation";
import axios from "../../../hoc/axios";
import { GET_ALL_GROUPS, GET_METHOD } from "../../../constants/apis";
import { connect } from "react-redux";
import { ADD_PROGRESS } from "../../../store/actions/LoaderActions";

class AddItemForm extends Component {
  state = {
    selectedFile: "",
    fields: {
      name: "",
      group_id: "",
      price: "",
      sort: "",
      description: "",
    },
    errors: {},
    Groups: [],
    featuredImage: "",
    selectedFile1: "",
    selectedFile2: "",
    selectedFile3: "",
    selectedFile4: "",
    // previewImages: [],
  };

  componentDidMount() {
    this.props.clearMessages();

    axios(
      GET_METHOD,
      GET_ALL_GROUPS + this.props.store_id,
      {},
      this.props.setLoadingProgress
    ).then((Response) => {
      if (Response.success) {
        this.setState({ Groups: Response.data.data });
      }
    });
  }

  render() {
    const { AddItemError, AddItemSuccess } = this.props;
    /*============================================================================================
                                    FUNCTIONS
===============================================================================================*/
    this.form = new ReactFormInputValidation(this);

    this.form.useRules({
      group_id: "required",
      pickup_place: "required",
      name: "required",
      sort: "required",
      price: "required",
      description: "required",
      image: "required",
    });

    this.form.onformsubmit = (fields) => {
      this.props.submitAddItem(this.state);
    };

    var onChangeHandler = (event) => {
      this.setState({
        selectedFile: event.target.files,
        image: URL.createObjectURL(event.target.files[0]),
      });
    };
    
    var onChangeHandler1 = (event) => {
      this.setState({
        selectedFile1: event.target.files,
        preview1: URL.createObjectURL(event.target.files[0]),
      });
    };

    var onChangeHandler2 = (event) => {
      this.setState({
        selectedFile2: event.target.files,
        preview2: URL.createObjectURL(event.target.files[0]),
      });
    };

    var onChangeHandler3 = (event) => {
      this.setState({
        selectedFile3: event.target.files,
        preview3: URL.createObjectURL(event.target.files[0]),
      });
    };
    var onChangeHandler4 = (event) => {
      this.setState({
        selectedFile4: event.target.files,
        preview4: URL.createObjectURL(event.target.files[0]),
      });
    };

    // var onChangeHandler2 = (event) => {
    //   var previewImages = [];

    //   /*eslint-disable-next-line*/
    //   for (var i = 0; i < event.target.files.length; i++) {
    //     previewImages.push(URL.createObjectURL(event.target.files[i]));
    //   }
    //   this.setState({
    //     selectedFile2: event.target.files,
    //     previewImages,
    //   });
    // };

    /*============================================================================================
                                    JSX
===============================================================================================*/
    return (

      <div
        className={
          Classes.Parent + " animate__animated animate__fadeIn animate__slow"
        }
      >
      {this.state.Groups.length > 0 ?
        <form onSubmit={this.form.handleSubmit}>
          <div className="form-group row add-item-form">
            <label className="form-label col-form-label">Category</label>
            <div className="form-field">
              <select
                id="group_id"
                name="group_id"
                value={this.state.fields.group_id}
                onChange={this.form.handleChangeEvent}
                className={this.state.errors.group_id ? "ErrorInput" : null}
              >
                <option value="">Select Category</option>
                {this.state.Groups.map((Element, Index) => {
                  return (
                    <option value={Element.id} key={Index}>
                      {Element.name}
                    </option>
                  );
                })}
              </select>
              <label className="error">
                {this.state.errors.group_id ? this.state.errors.group_id : ""}
              </label>
            </div>
          </div>

          <div className="form-group row add-item-form">
            <label className="form-label col-form-label">Item Name</label>
            <div className="form-field">
              <input
                type="text"
                name="name"
                placeholder="Item Name *"
                data-attribute-name="Item Name"
                className={this.state.errors.name ? "ErrorInput" : null}
                onChange={this.form.handleChangeEvent}
                value={this.state.fields.name}
              />
              <label className="error">
                {this.state.errors.name ? this.state.errors.name : ""}
              </label>
            </div>
          </div>


          <div className="form-group row add-item-form">
            <label className="form-label col-form-label">Price</label>
            <div className="form-field">
              <input
                type="number"
                name="price"
                placeholder="Price *"
                className={this.state.errors.price ? "ErrorInput" : null}
                onChange={this.form.handleChangeEvent}
                value={this.state.fields.price}
              />
              <label className="error">
                {this.state.errors.price ? this.state.errors.price : ""}
              </label>
            </div>
          </div>

          <div className="form-group row add-item-form">
            <label className="form-label col-form-label">Description</label>
            <div className="form-field">
              <textarea
                name="description"
                placeholder="Description *"
                className={this.state.errors.description ? "ErrorInput" : null}
                onChange={this.form.handleChangeEvent}
                value={this.state.fields.description}
              />
              <label className="error">
                {this.state.errors.description
                  ? this.state.errors.description
                  : ""}
              </label>
            </div>
          </div>

          <div className="form-group row add-item-form">
            <div className="form-field">
              <input
                type="number"
                placeholder="Sorting Order (smaller number goes top)"
                name="sort"
                className={this.state.errors.sort ? "ErrorInput" : null}
                onChange={this.form.handleChangeEvent}
                value={this.state.fields.sort}
              />
              <label className="error">
                {this.state.errors.sort ? this.state.errors.sort : ""}
              </label>
            </div>
          </div>

          <div className="form-group row add-item-form">
            <p>Please upload 1 main image and 4 sub images</p>
            <label className="error">
                {this.state.errors.image
                  ? this.state.errors.image
                  : ""}
              </label>
            <label className="form-label col-form-label">Main image</label>
            <div className={`InputDialog main-image-uploader ${Classes.FileUpload}`} >
              <input
                type="file"
                name="image"
                onChange={(event) => {
                  onChangeHandler(event);
                }}
              />
              {/*eslint-disable-next-line*/}
              <img src={this.state.image?this.state.image: '/nopreview.png'}  />
              {/* <span className="text-muted">
                <br />
              Upload Featured Image (Only one)
            </span> */}
            </div>

          </div>
          <br />
          <div className="sub-images">
            <div className={`InputDialog ${Classes.FileUpload}`} style={{}}>
              
              {/*eslint-disable-next-line*/}
              <img src={this.state.preview1?this.state.preview1: '/nopreview.png'}style={{}} />
              <input
                type="file"
                onChange={(event) => {
                  onChangeHandler1(event);
                }}
              />
              <span className="text-muted">
                <br />
                {/* Upload Preview Image (Only one) */}
              </span>
            </div>
            <div className={`InputDialog ${Classes.FileUpload}`} style={{}}>
              
              {/*eslint-disable-next-line*/}
              <img src={this.state.preview2?this.state.preview2: '/nopreview.png'} style={{}} />
              <input
                type="file"
                onChange={(event) => {
                  onChangeHandler2(event);
                }}
              />
              <span className="text-muted">
                <br />
                {/* Upload Preview Image (Only one) */}
              </span>
            </div>
            <div className={`InputDialog ${Classes.FileUpload}`} style={{}}>
              
              {/*eslint-disable-next-line*/}
              <img src={this.state.preview3?this.state.preview3: '/nopreview.png'} style={{}} />
              <input
                type="file"
                onChange={(event) => {
                  onChangeHandler3(event);
                }}
              />
              <span className="text-muted">
                <br />
                {/* Upload Preview Image (Only one) */}
              </span>
            </div>
            <div className={`InputDialog ${Classes.FileUpload}`} style={{}}>
              
              {/*eslint-disable-next-line*/}
              <img src={this.state.preview4?this.state.preview4: '/nopreview.png'} style={{}} />
              <input
                type="file"
                onChange={(event) => {
                  onChangeHandler4(event);
                }}
              />
              <span className="text-muted">
                <br />
                {/* Upload Preview Image (Only one) */}
              </span>
            </div>
          </div>
         
          {/* <div className="InputDialog">
            <div className="InputIcon">
              <i className="fas fa-sort"></i>
            </div>
            <input
              type="number"
              placeholder="Sorting Order *"
              name="sort"
              className={this.state.errors.sort ? "ErrorInput" : null}
              onChange={this.form.handleChangeEvent}
              value={this.state.fields.sort}
            />
            <label className="error">
              {this.state.errors.sort ? this.state.errors.sort : ""}
            </label>
          </div> */}

          {AddItemError ? (
            <div className="ErrorsModule">
              <span className="text-muted">
                <svg
                  width="1.3em"
                  height="1.3em"
                  style={{ marginTop: "-3px" }}
                  viewBox="0 0 16 16"
                  className="bi bi-bug"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.355.522a.5.5 0 0 1 .623.333l.291.956A4.979 4.979 0 0 1 8 1c1.007 0 1.946.298 2.731.811l.29-.956a.5.5 0 1 1 .957.29l-.41 1.352A4.985 4.985 0 0 1 13 6h.5a.5.5 0 0 0 .5-.5V5a.5.5 0 0 1 1 0v.5A1.5 1.5 0 0 1 13.5 7H13v1h1.5a.5.5 0 0 1 0 1H13v1h.5a1.5 1.5 0 0 1 1.5 1.5v.5a.5.5 0 1 1-1 0v-.5a.5.5 0 0 0-.5-.5H13a5 5 0 0 1-10 0h-.5a.5.5 0 0 0-.5.5v.5a.5.5 0 1 1-1 0v-.5A1.5 1.5 0 0 1 2.5 10H3V9H1.5a.5.5 0 0 1 0-1H3V7h-.5A1.5 1.5 0 0 1 1 5.5V5a.5.5 0 0 1 1 0v.5a.5.5 0 0 0 .5.5H3c0-1.364.547-2.601 1.432-3.503l-.41-1.352a.5.5 0 0 1 .333-.623zM4 7v4a4 4 0 0 0 3.5 3.97V7H4zm4.5 0v7.97A4 4 0 0 0 12 11V7H8.5zM12 6H4a3.99 3.99 0 0 1 1.333-2.982A3.983 3.983 0 0 1 8 2c1.025 0 1.959.385 2.666 1.018A3.989 3.989 0 0 1 12 6z"
                  />
                </svg>
                &nbsp; Please fix the following issues :
              </span>
              <br />
              {Object.values(AddItemError).map((Element, Index) => {
                return (
                  <span key={Index}>
                    {Index + 1 + " " + Element}
                    <br />
                  </span>
                );
              })}
            </div>
          ) : null}
          {AddItemSuccess ? (
            <div className="ErrorsModule">
              <div style={{ position: "relative" }}>
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    window.location.reload();
                  }}
                >
                  Add new Item
                </button>
              </div>
              <span className="text-muted">
                <svg
                  width="1.3em"
                  height="1.3em"
                  style={{ marginTop: "-3px" }}
                  viewBox="0 0 16 16"
                  className="bi bi-check-circle"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                  />
                  <path
                    fillRule="evenodd"
                    d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                  />
                </svg>{" "}
                &nbsp; Item added successfully.
              </span>
            </div>
          ) : null}
          <div className="addmore">
            {AddItemSuccess ? null : <button >Add Item</button>}
          </div>
        </form> 
        : 
          <h4> Please add Category before adding item. Please <a href="/MyGroups"> Click Here</a> to create category.</h4>
        }
      </div>
    );
  }
}

const dispatchToProps = (dispatch) => {
  return {
    setLoadingProgress: (progress) => {
      dispatch({ type: ADD_PROGRESS, progress });
    },
  };
};

export default connect(null, dispatchToProps)(AddItemForm);
