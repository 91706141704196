import React, { Component } from "react";
import Classes from "../styles/MyItems/MyItems.module.css";
import Header from "../components/Panel/Header";
import { connect } from "react-redux";
import axios from "../hoc/axios";
import { Route, history } from "react-router";
import {
  ORDER_LIST,
  POST_METHOD
} from "../constants/apis";
import { ADD_PROGRESS } from "../store/actions/LoaderActions";
import CurrencyFormat from 'react-currency-format';

class OrderList extends Component {

	state = {
		orderListError:true,
		orderListSuccess:false,
		rows:[],
		filter:'',
	};
	user =  JSON.parse(localStorage.getItem('user'));
	componentDidMount() {
	const reqData = {"driver_id": this.user.id, "status": this.state.filter };
    axios(POST_METHOD, ORDER_LIST, reqData, this.props.setLoadingProgress).then(
      (Response) => {
        if (Response.success) {
	          const data = [];
	          /*eslint-disable-next-line*/
	          	this.setState({
	                rows: Response.data.data,
	                orderListSuccess: true,
	                orderListError:false,
	            });
	        }
	      }
	    );
	  }

	render() {
		const LinksList = [
	      {
	        name: "Home Page",
	        link: "/Dashboard",
	      },
	    ];
	    
		return (
			<section className={Classes.Parent}>
			<Header LinksList={LinksList} />
          	<div className={Classes.Child}>
            <div className="item-contailer">
                  <div className="heading-row">
                      <div className="column column-left"><span>My Orders(s)</span></div>
                      <div className="column column-right">Total: <span>{ this.state.rows.length }</span> items(s)</div>
                  </div>
                  <table>
                  <thead>
                  	<tr>
                  		<th><b>OrderID#</b></th>
                  		<th><b>Status</b></th>
                  		<th><b>Order Amount</b></th>
                  		<th><b>Pickup Address</b></th>
                  		<th><b>Delivery Address</b></th>
  
                  		<th><b>Distance</b></th>
                  	</tr>
                  </thead>

                  	<tbody>
						{ this.state.rows.map((row:any) => 
						<tr className="list-container" key={row.id}>
						    <td>
						        ME000{row.id}  
						    </td>
						     <td>
						        {row.status}  
						    </td>
						     <td>
						        <CurrencyFormat value={row.address.amount} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}</div>} />
						    </td>
						     <td>
						        {row.address.store_address}  
						    </td>
						     <td>
						        {row.address.customer_address} 
						    </td>
						   
						     <td>
						        {row.distance}  
						    </td>
						</tr>
						)}
                  	</tbody>
                  </table>
                  
              </div>         
          	</div>
          	</section>
		);
	}
}

const dispatchToProps = (dispatch) => {
  return {
    setLoadingProgress: (progress) => {
      
    },
  };
};

export default connect(dispatchToProps) (OrderList);