/*============================================================================================
                       SCREEN FOR AUTHENTICATION OF VENDOR
===============================================================================================*/
import React, { Component } from "react";
import Classes from "../styles/UserAuthentication.module.css";
import LoginForm from "../components/LoginForm";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import RegistrationForm from "../components/RegistrationForm";
import StoreRegistrationForm from "../components/StoreRegistrationForm";
import axios from "../hoc/axios";
import Main from "../components/Main";

import {
  VENDOR_REGISTRATION,
  VENDOR_STORE,
  VENDOR_LOGIN,
  POST_METHOD,
} from "../constants/apis";
import { loginUser } from "../store/actions/Authentication";
import { ADD_PROGRESS } from "../store/actions/LoaderActions";

/*============================================================================================
                                    CLASS
===============================================================================================*/
export class UserAuthentication extends Component {
  state = {
    RegisterFormError: null,
    RegisterFormSuccess: null,
    LoginFormError: null,
    LoginFormSuccess: null,
  };
  render() {
    /*============================================================================================
                                    FUNCTIONS
===============================================================================================*/

    //REGISTER STORE OWNER

    const registerStoreOwner = (state, redirectToHome = true) => {
      this.setState({ RegisterFormSuccess: null, RegisterFormError: null });

      const data = new FormData();
      if (state.selectedFile) {
        data.append("profile_image", state.selectedFile);
      }
      data.append("email", state.fields.email);
      data.append("password", state.fields.password);
      data.append("first_name", state.fields.first_name);
      data.append("middle_name", state.fields.middle_name);
      data.append("last_name", state.fields.last_name);
      data.append("phone", state.fields.phone);
      data.append("role_id", 2);

      axios(
        POST_METHOD,
        VENDOR_REGISTRATION,
        data,
        this.props.setLoadingProgress
      ).then((Response) => {
        if (Response.success) {
          const { data } = Response.data;
          localStorage.setItem("user", JSON.stringify(data));
          this.setState({ RegisterFormSuccess: true });
          this.setState({ LoginFormSuccess: true });
          this.props.LOGIN(false, data);
          window.location.href = redirectToHome ? "/" : "/StoreEdit";
        } else {
          this.setState({ RegisterFormError: Response.message });
        }
      });
    };

    //REGISTER STORE
    const registerStore = (state) => {
      this.setState({
        StoreRegistrationSuccess: null,
        StoreRegistrationError: null,
      });

      if (!state.selectedFile) {
        return this.setState({
          StoreRegistrationError: { message: "Please upload a store banner" },
        });
      }

      const data = new FormData();

      data.append("store_image", state.selectedFile, state.selectedFile.name);
      data.append("store_name", state.fields.store_name);
      data.append("address", state.fields.address);
      data.append("city", state.fields.city);
      data.append("province", state.fields.province);
      data.append("nation", state.fields.nation);

      axios(
        POST_METHOD,
        VENDOR_STORE,
        data,
        this.props.setLoadingProgress
      ).then((Response) => {
        if (Response.success) {
          window.location.href = "/MyStore?status=done";
          this.props.STORECREATED();
        } else {
          this.setState({
            StoreRegistrationError: Response.message,
          });
        }
      });
    };

    //LOG IN STORE OWNER
    const loginStoreOwner = (fields, remember) => {
      this.setState({ LoginFormSuccess: null, LoginFormError: null });

      axios(
        POST_METHOD,
        VENDOR_LOGIN,
        { ...fields },
        this.props.setLoadingProgress
      ).then((Response) => {
        if (Response.success) {
          const { data } = Response.data;
          localStorage.setItem("user", JSON.stringify(data));
          this.setState({ LoginFormSuccess: true });

          this.props.LOGIN(remember, data);
          window.location.href = "/";
        } else {
          this.setState({ LoginFormError: Response.message });
        }
      });
    };
    /*============================================================================================
                                    JSX
===============================================================================================*/
    return (
      <section className={Classes.Parent}>
        <div className={Classes.Child}>
          <div className="row">
            <div className={`col-3 ${Classes.CompanyName}`}><img className="log" alt="Logo"  src="logo.png" style={{ height: "36px" }} /></div>
            <div className="col-md-12" style={{ padding: "20px" }}>
              <Route path="/Login" exact>
                <title>MoliEats | Login</title>
                <h2>Login to your account.</h2>
                <br />

                <LoginForm loginStoreOwner={loginStoreOwner} {...this.state} />
              </Route>
              <Route path="/main" component={Main} exact/>
              <Route path="/Registration" exact>
                <title>MoliEats | Registration</title>
                <h2>Create new account.</h2>
                <br />

                <RegistrationForm
                  registerStoreOwner={registerStoreOwner}
                  {...this.state}
                />
              </Route>
              <Route path="/StoreRegistration" exact>
                <title>Store Owner | Store Registration</title>
                <h2>
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    style={{ marginTop: "-10px" }}
                    className="bi bi-shop"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M0 15.5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5zM3.12 1.175A.5.5 0 0 1 3.5 1h9a.5.5 0 0 1 .38.175l2.759 3.219A1.5 1.5 0 0 1 16 5.37v.13h-1v-.13a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.13H0v-.13a1.5 1.5 0 0 1 .361-.976l2.76-3.22z"
                    />
                    <path d="M2.375 6.875c.76 0 1.375-.616 1.375-1.375h1a1.375 1.375 0 0 0 2.75 0h1a1.375 1.375 0 0 0 2.75 0h1a1.375 1.375 0 1 0 2.75 0h1a2.375 2.375 0 0 1-4.25 1.458 2.371 2.371 0 0 1-1.875.917A2.37 2.37 0 0 1 8 6.958a2.37 2.37 0 0 1-1.875.917 2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.5h1c0 .76.616 1.375 1.375 1.375z" />
                    <path d="M4.75 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm3.75 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm3.75 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                    <path
                      fillRule="evenodd"
                      d="M2 7.846V7H1v.437c.291.207.632.35 1 .409zm-1 .737c.311.14.647.232 1 .271V15H1V8.583zm13 .271a3.354 3.354 0 0 0 1-.27V15h-1V8.854zm1-1.417c-.291.207-.632.35-1 .409V7h1v.437zM3 9.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5V15H7v-5H4v5H3V9.5zm6 0a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-4zm1 .5v3h2v-3h-2z"
                    />
                  </svg>
                  &nbsp; Register your Store.
                  <a href="/Dashboard" style={{ color: "#f05a28" }}>
                    <svg
                      width="1.3em"
                      height="1.3em"
                      viewBox="0 0 16 16"
                      className="bi bi-arrow-right-square-fill"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ float: "right" }}
                    >
                      <path
                        fillRule="evenodd"
                        d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm5.646 10.646a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L9.793 7.5H5a.5.5 0 0 0 0 1h4.793l-2.147 2.146z"
                      />
                    </svg>
                  </a>
                </h2>

                <br />
                <StoreRegistrationForm
                  {...this.props}
                  registerStore={registerStore}
                  store_created={false}
                  displayAlert={false}
                  {...this.state}
                />
              </Route>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

/*============================================================================================
                                    REDUX STARTS
===============================================================================================*/
const dispatchToProps = (dispatch) => {
  return {
    LOGIN: (remember, data) => {
      dispatch(loginUser(remember, data));
    },
    STORECREATED: () => {
      dispatch({ type: "STORE_CREATED" });
    },
    setLoadingProgress: (progress) => {
      dispatch({ type: ADD_PROGRESS, progress });
    },
  };
};

const stateToProps = (state) => {
  return {};
};
/*============================================================================================
                                    REDUX ENDS
===============================================================================================*/
export default connect(stateToProps, dispatchToProps)(UserAuthentication);
