import * as actionTypes from "../actions/UserDetails";

const initialState = {
  first_name: "",
  last_name: "",
  phone: "",
  store_created: false,
  updated_at: "",
  create_at: "",
  store_id: "",
  id: "",
};

const UserDetailsReducer = (state = initialState, action) => {
  //eslint-disable-next-line
  switch (action.type) {
    case actionTypes.SAVE_USER_DETAILS: {
      return {
        ...state,
        ...action.data,
      };
    }
    case actionTypes.STORE_CREATED: {
      return {
        ...state,
        store_created: true,
      };
    }
  }

  return state;
};

export default UserDetailsReducer;
