import React, {Component} from 'react';
import './Message.css';
export default class Message extends Component {
  render() {
    const user = JSON.parse(localStorage.getItem("user"));
    const driver = JSON.parse(localStorage.getItem("driver"));

    return (
      
      <div className="message">
            <div className="chat-wrapper">
              {this.props.message.User==user.id?(
              <div className="sn">
                <div className="sn-m">{this.props.message.Text}</div>
              </div>)
              :(<div className="rc">
                <div className="rc-m">{this.props.message.Text}</div>
              </div>)}

            </div>
      </div>
    )
  }
}