import React, { Component, Fragment } from "react";
import Header from "./Panel/Header";
import Classes from "../styles/MyStore/MyStore.module.css";
import { connect } from "react-redux";

const { compose, withProps, withStateHandlers } = require("recompose");
//const FaAnchor = require("react-icons/lib/fa/anchor");

const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} = require("react-google-maps");

export class Profile extends Component {
	state = {};

	static defaultProps = {
      googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyD4doZrX7pSltqAe8dRnZBcG2N6Ig8QMeU&v=3.exp&libraries=geometry,drawing,places",
    }

    data = JSON.parse(localStorage.getItem('user'));

    CMap = compose(
            withStateHandlers(() => ({
              isOpen: true,
            }), {
              onToggleOpen: ({ isOpen }) => () => ({
                isOpen: !isOpen,
              })
            }),
            withScriptjs,
            withGoogleMap
          )(props =>
	        <GoogleMap
	          google={this.props.google}
	          defaultZoom={9}
	          defaultCenter={{ lat: parseFloat(this.data.latitude), lng: parseFloat(this.data.longitude) }}
	        >

	        <Marker key="center"
	            position={{ lat: parseFloat(this.data.latitude), lng: parseFloat(this.data.longitude) }}
	          />

	        </GoogleMap>
	    );

	render() {
		console.log(this.data)
		const LinksList = [
	      {
	        name: "Profile",
	        link: "/Profile",
	      },
	    ];

	   
      	
      		return (
				
				<section className={Classes.Parent}>
		            <Header LinksList={LinksList} />
		            <section className="main-section">
		              
		                <section className="chart-section">
		                    <div className="inner-wrapper">
		                        <div className="mapDiv">
		                            <Fragment>
		                                <this.CMap

		                                    googleMapURL={this.props.googleMapURL}
		                                    loadingElement={<div style={{ height: `100%` }} />}
		                                    containerElement={<div style={{ height: `100%` }} />}
		                                    mapElement={<div style={{ height: `100%` }} />}
		                                    center= {{ lat: parseFloat(this.data.latitude), lng: parseFloat(this.data.longitude) }}
		                                >
		                                    
		                                </this.CMap>
		                            </Fragment>

		                            
		                        </div>
		                    </div>
		                </section>
		            </section>
		          </section>
			);
      	
	}

}


const stateToProps = (state) => {
  return {
    first_name: state.user.first_name,
    last_name: state.user.last_name,
    id: state.user.id,
    email:state.user.email,
    profile_image: state.user.profile_image,
    store_created: state.user.store_created,
    store_id: state.user.store_id,
  };
};

export default connect(stateToProps)(Profile);