import Cookies from "universal-cookie";
import * as actionTypes from "../actions/Authentication";
const cookie = new Cookies();

const getCookies = () => {
  var authenticated = cookie.get("MEKey") ? true : false;
  var token = cookie.get("MEKey") ? cookie.get("MEKey") : null;
  var email = cookie.get("MEEmail") ? cookie.get("MEEmail") : null;
  return { authenticated, token, email };
};

const initialState = {
  ...getCookies(),
};

const AuthenticationReducer = (state = initialState, action) => {
  //eslint-disable-next-line
  switch (action.type) {
    case actionTypes.LOGIN: {
      return {
        ...state,
      };
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
      };
    }
  }

  return state;
};

export default AuthenticationReducer;
