/*============================================================================================
                       ACTIONS FOR USER DETAILS
===============================================================================================*/
export const SAVE_USER_DETAILS = "SAVE_USER_DETAILS";
export const STORE_CREATED = "STORE_CREATED";

export const CAT_CREATED=()=>{
    return {
        type:STORE_CREATED
    }
}
