import React, { Component } from "react";
import axios from "../../../hoc/axios";
import qs from "query-string";
import DeletePopup from "../../../hoc/DeletePopup";
import { connect } from "react-redux";
import {
  DELETE_GROUP,
  EDIT_GROUP,
  POST_METHOD,
  GROUP_INFO,
  DELETE_METHOD,
} from "../../../constants/apis";
import { ADD_PROGRESS } from "../../../store/actions/LoaderActions";

export class EditGroup extends Component {
  state = {
    CategoryName: "",
  };

  componentDidMount() {
    const parsed = qs.parse(this.props.location.search);

    axios(
      POST_METHOD,
      GROUP_INFO,
      { id: parsed.id },
      this.props.setLoadingProgress
    ).then((Response) => {
      if (Response.success) {
        this.setState({ CategoryName: Response.data.data.name });
      }
    });
  }
  render() {
    const { EditGroupError, EditGroupSuccess } = this.state;
    const parsed = qs.parse(this.props.location.search);

    const DeleteGroup = () => {
      axios(
        DELETE_METHOD,
        DELETE_GROUP,
        { id: parsed.id },
        this.props.setLoadingProgress
      );
    };
    const SubmitUpdateHandler = () => {
      this.setState({
        EditGroupError: "",
        EditGroupSuccess: "",
      });
      if (this.state.CategoryName === "") {
        return this.setState({
          EditGroupError: { message: "Please enter a valid name" },
        });
      }
      axios(
        POST_METHOD,
        EDIT_GROUP,
        { id: parsed.id, name: this.state.CategoryName },
        this.props.setLoadingProgress
      ).then((Response) => {
        if (Response.success) {
          return this.setState({ EditGroupSuccess: true });
        } else {
          this.setState({
            EditGroupError: Response.message,
          });
        }
      });
    };

    return (
      <div style={{ padding: "5px 30px 10px 30px" }}>
        {/* <input
          placeholder="Enter group name to Update"
          style={{ paddingLeft: "0px", marginTop: "-30px" }}
        />
        <button>Edit Name</button> */}
        
        <hr /> Category Name: &nbsp;
        <input
          style={{
            border: "1px solid #f05a28",

            paddingLeft: "5px",
          }}
          type="text"
          value={this.state.CategoryName}
          className="form-control"
          onChange={(event) => {
            this.setState({ CategoryName: event.target.value });
          }}
        />
        <br />
        <button
          onClick={() => {
            SubmitUpdateHandler();
          }}
        >
          Update
        </button>
        {EditGroupError ? (
          <div className="ErrorsModule">
            <span className="text-muted">
              <svg
                width="1.3em"
                height="1.3em"
                style={{ marginTop: "-3px" }}
                viewBox="0 0 16 16"
                className="bi bi-bug"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.355.522a.5.5 0 0 1 .623.333l.291.956A4.979 4.979 0 0 1 8 1c1.007 0 1.946.298 2.731.811l.29-.956a.5.5 0 1 1 .957.29l-.41 1.352A4.985 4.985 0 0 1 13 6h.5a.5.5 0 0 0 .5-.5V5a.5.5 0 0 1 1 0v.5A1.5 1.5 0 0 1 13.5 7H13v1h1.5a.5.5 0 0 1 0 1H13v1h.5a1.5 1.5 0 0 1 1.5 1.5v.5a.5.5 0 1 1-1 0v-.5a.5.5 0 0 0-.5-.5H13a5 5 0 0 1-10 0h-.5a.5.5 0 0 0-.5.5v.5a.5.5 0 1 1-1 0v-.5A1.5 1.5 0 0 1 2.5 10H3V9H1.5a.5.5 0 0 1 0-1H3V7h-.5A1.5 1.5 0 0 1 1 5.5V5a.5.5 0 0 1 1 0v.5a.5.5 0 0 0 .5.5H3c0-1.364.547-2.601 1.432-3.503l-.41-1.352a.5.5 0 0 1 .333-.623zM4 7v4a4 4 0 0 0 3.5 3.97V7H4zm4.5 0v7.97A4 4 0 0 0 12 11V7H8.5zM12 6H4a3.99 3.99 0 0 1 1.333-2.982A3.983 3.983 0 0 1 8 2c1.025 0 1.959.385 2.666 1.018A3.989 3.989 0 0 1 12 6z"
                />
              </svg>
              &nbsp; Please fix the following issues :
            </span>
            <br />
            {Object.values(EditGroupError).map((Element, Index) => {
              return (
                <span key={Index}>
                  {Index + 1 + " " + Element}
                  <br />
                </span>
              );
            })}
          </div>
        ) : null}
        {EditGroupSuccess ? (
          <div className="ErrorsModule">
            <span className="text-muted">
              <svg
                width="1.3em"
                height="1.3em"
                style={{ marginTop: "-3px" }}
                viewBox="0 0 16 16"
                className="bi bi-check-circle"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                />
                <path
                  fillRule="evenodd"
                  d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                />
              </svg>{" "}
              &nbsp; Category name updated.
            </span>
          </div>
        ) : null}
        <hr />
      </div>
    );
  }
}
const dispatchToProps = (dispatch) => {
  return {
    setLoadingProgress: (progress) => {
      dispatch({ type: ADD_PROGRESS, progress });
    },
  };
};

export default connect(null, dispatchToProps)(EditGroup);
