import React, { Component } from "react";
import Header from "../components/Panel/Header"
import { GET_USER, REQ_STATUS, START_CHAT,ADD_ORDER, POST_METHOD } from "../constants/apis";
import Classes from "../styles/MyStore/MyStore.module.css";
import queryString from 'query-string';
import axios from "../hoc/axios";
import { ADD_PROGRESS } from "../store/actions/LoaderActions";
import { connect } from "react-redux";
import logo from '../assets/logo.png';
import '../styles/chat.css';
import Form from '../components/Form/Form.js';
import firebase from 'firebase';
import firebaseConfig from './../config';
firebase.initializeApp(firebaseConfig)

export class Chat extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
	      user: null,
	      driver:null,
	      orderPlaced:false,
	      accepted:false,
	      notification_id:null
	    }
	}
	
	componentDidMount() {
		const user = JSON.parse(localStorage.getItem("user"));
	  	const value = queryString.parse(this.props.location.search);
		const driver_id = value.id;
		
		axios(POST_METHOD, START_CHAT, {"driver_id": driver_id, "user_id":user.id} , this.props.setLoadingProgress).then( (Response) => {
	      	if (Response.success) {
		        	localStorage.setItem("driver", JSON.stringify(Response.data.data.driver));
		        	localStorage.setItem("user", JSON.stringify(Response.data.data.customer));
		        	this.setState({
		        		user: Response.data.data.customer,
		        		driver: Response.data.data.driver,
		        		notification_id: Response.data.data.chat.id
		        	});
		       
			}
		});

		firebase.auth().onAuthStateChanged(user => {
	      this.setState({ user });
	    });

		
		this.refreshPage = setInterval(this.getData, 15000); 
		
  	}

  	getData = () => {
  		if(this.state.accepted!=false) clearInterval(this.refreshPage);
  		if(this.state.notification_id!==null && this.state.accepted===false){
	        axios(POST_METHOD, REQ_STATUS, {"id": this.state.notification_id} , this.props.setLoadingProgress).then( (Response) => {
	        	if (Response.success) {
	        		if(Response.data.data!==null){
	        			if(Response.data.data==0){
	        				window.location.href = "/driver-availability";
	        			}
	        			this.setState({
	        				accepted: Response.data.data,
	        			});
	        		}
	        	}
	        });
	    }
    }



  	handleSignIn() {
	    const provider = new firebase.auth.GoogleAuthProvider();
	    firebase.auth().signInWithPopup(provider);
	}

	handleLogOut() {
	    firebase.auth().signOut();
	}

	render() {
		const LinksList = [
	      {
	        name: "Chat",
	        link: "/chat",
	      },
	    ];

	    const assignOrder = (e) => {
	    	var order = JSON.parse(localStorage.getItem("delivery_data"));
	    	const reqData = {
	    				"amount":order.amount,
	    				"customerLocation": order.customerLocation,
					    "storeLocation": order.storeLocation,
					    "customerLat":order.customerLat,
					    "customerLng":order.customerLng,
					    "storeLat":order.storeLat,
					    "storeLng":order.storeLng,
					    "driver_id":this.state.driver.id,
					    "customer_id": this.state.user.id,
					    "distance": this.state.driver.distance,
					    "status": 5
	    			  }
          	axios(POST_METHOD, ADD_ORDER, reqData , this.props.setLoadingProgress).then(
		      (Response) => {
		      	
		        if (Response.success) {
		        	this.setState({
		        		orderPlaced:true
		        	});
		        }
		      }
		    );
        }

        
		return (
			<section className={Classes.Parent}>
	          <Header LinksList={LinksList} />
	          <div className={Classes.form}>
	          		{this.state.accepted===false?<h3 className="app__heading">Waiting driver's response...</h3>:
	          		this.state.accepted==0?<h3 className="app__heading">Driver declined your request, Please select another driver.</h3>:
	          		this.state.orderPlaced?(<h3 className="app__heading">You assigned driver to your order</h3>):
	          		(<div className="app">
				        <div className="app__header">
				          	<button className="app__button" onClick={assignOrder} >Assign this driver to my order </button>
				        </div>
				        <div className="app__list">
				           { (this.state.user!==null && this.state.driver!==null)?<Form data={this.state.user} driver={this.state.driver} />:''}
				        </div>
				    </div>)}
	          </div>
	         </section>
		);
	}

}
const dispatchToProps = (dispatch) => {
  return {
    setLoadingProgress: (progress) => {
      dispatch({ type: ADD_PROGRESS, progress });
    },
  };
};

export default connect(null, dispatchToProps)(Chat);