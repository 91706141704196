import React, { Component } from "react";
import Classes from "../../../styles/MyStore/DisplayStore.module.css";
import axios from "../../../hoc/axios";
import { VENDOR_STORE, GET_METHOD } from "../../../constants/apis";
import qs from "query-string";
import { Link } from "react-router-dom";
import StoreEdit from "./StoreEdit";
import { connect } from "react-redux";
import { ADD_PROGRESS } from "../../../store/actions/LoaderActions";

export class DisplayStore extends Component {
  state = {
    address: "",
    city: "",
    created_at: "",
    id: 21,
    lat: "",
    long: "",
    nation: "",
    store_type: "",
    owner: 0,
    province: "",
    store_image: "",
    store_name: "",
    store_id: "",
    updated_at: "",
  };
  componentDidMount() {
    axios(GET_METHOD, VENDOR_STORE, {}, this.props.setLoadingProgress).then(
      (Response) => {
        if (Response.success) {
          this.setState({
            ...Response.data.data,
          });
        }
      }
    );
  }
  render() {
    const parsed = qs.parse(this.props.location.search);
    const RefreshData = () => {
      this.setState({
        address: "",
        city: "",
        created_at: "",
        id: 21,
        lat: "",
        long: "",
        nation: "",
        store_type: "",
        owner: 0,
        province: "",
        store_image: "",
        store_id: "",
        store_name: "",
        updated_at: "",
      });
      axios(GET_METHOD, VENDOR_STORE, {}, this.props.setLoadingProgress).then(
        (Response) => {
          if (Response.success) {
            this.setState({
              ...Response.data.data,
            });
          }
        }
      );
    };
    const {
      store_image,
      store_name,
      city,
      store_type,
      address,
      province,
      nation,
      store_id,
    } = this.state;
    return (
      <>
        <div className={Classes.Parent}>
          <div className="row ">
            <div className="col-md-12 image_row">
              <img src={store_image} className="store_img" alt="Store banner" />
            </div>
          </div>

          <div className="row">
            <div className="col-6 text-right my-2">
              <strong>Store Type*</strong> :
            </div>
            <div className="col-6 text-left my-2">{store_type}</div>
          </div>

          <div className="row">
            <div className="col-6 text-right my-2">
              <strong>Store Name*</strong> :
            </div>
            <div className="col-6 text-left my-2">{store_name}</div>
          </div>

          <div className="row">
            <div className="col-6 text-right my-2">
              <strong>Unique Store ID</strong> :
            </div>
            <div className="col-6 text-left my-2">{store_id}</div>
          </div>

          <div className="row">
            <div className="col-6 text-right my-2">
              <strong>Address*</strong> :
            </div>
            <div className="col-6 text-left my-2">{address}</div>
          </div>

          <div className="row">
            <div className="col-6 text-right my-2">
              <strong>City*</strong> :
            </div>
            <div className="col-6 text-left my-2">{city}</div>
          </div>

          <div className="row">
            <div className="col-6 text-right my-2">
              <strong>province*</strong> :
            </div>
            <div className="col-6 text-left my-2">{province}</div>
          </div>

          <div className="row">
            <div className="col-6 text-right my-2">
              <strong>Country*</strong> :
            </div>
            <div className="col-6 text-left my-2">{nation}</div>
          </div>
        </div>
        {parsed.status ? (
          <div className="ErrorsModule" style={{ position: "relative" }}>
            <span className="text-muted">
              <svg
                width="1.3em"
                height="1.3em"
                style={{ marginTop: "-3px" }}
                viewBox="0 0 16 16"
                className="bi bi-check-circle"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                />
                <path
                  fillRule="evenodd"
                  d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                />
              </svg>{" "}
              &nbsp; Store added successfully.
            </span>
          </div>
        ) : null}

        <div className="row">
          <div className="col-6 text-center">
            <div className="">
              <Link to="/StoreEdit">EDIT</Link>
            </div>
          </div>
          <div className="col-6 text-center">
            <div className="elementRight">
              <Link to="/Dashboard">GO TO MAIN</Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const dispatchToProps = (dispatch) => {
  return {
    setLoadingProgress: (progress) => {
      dispatch({ type: ADD_PROGRESS, progress });
    },
  };
};

export default connect(null, dispatchToProps)(DisplayStore);
