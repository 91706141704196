import React, { Component } from "react";
import Classes from "../styles/MyStore/MyStore.module.css";
import Logo from '../assets/logo.png'
import axios from "../hoc/axios"
import $ from 'jquery'
import UserIcon from '../assets/icon_role_user.jpg'
import { ITEMS, POST_METHOD } from "../constants/apis"
import  "./searchComponent/FoodSearch.scss"
import { connect } from "react-redux"
import { ADD_PROGRESS } from "../store/actions/LoaderActions"
import queryString from 'query-string'
import { useParams } from "react-router"
import Cookies from "universal-cookie"
import NavBar from "./Panel/NavBar"


export class FoodSearch extends Component {
    state = {
        search:'',
        data:[],
        success: false,
        menu:false
    };

    componentDidMount() {
        let params = queryString.parse(this.props.location.search);
        this.setState({ 
                        search: params.item
                    });
        if(params.item!==undefined){
            axios(
            POST_METHOD,
            ITEMS,
            { page:0, name: params.item },
            this.props.setLoadingProgress
            ).then((Response) => {
                if (Response.success===true) {
                    this.setState({ 
                        data: Response.data.data,
                        success:true
                    });
                }
            });
        }else{
            axios(
            POST_METHOD,
            ITEMS,
            { page:0, group_id: params.category },
            this.props.setLoadingProgress
            ).then((Response) => {
                if (Response.success===true) {
                    this.setState({ 
                        data: Response.data.data,
                        success:true
                    });
                }
            });
        }
    }

    render() {

        const cookie = new Cookies();
        var authenticated = cookie.get("MEKey") ? true : false;

        const clickHandler = (id) => {

            window.location.href = '/products/'+id
        }

        const clickEventHandler = (menu) => {
            this.setState({
                menu: menu
            })
        }

        $(".bm-cross-button").click(function(){
            $(".navigation").removeClass("visible");
        });


        return (
                <div>
                    <div className="main-section-wrapper">
                    <section className="layer-container">
                        <div className="navigation">
                            {authenticated===true?<NavBar onClickMenu={clickEventHandler} />:<div className="logo"><a href="/"><img src={Logo} alt=""/></a></div>}
                            
                            {authenticated===false?<div className="btn-row"><a href="/Login" className="btn">Sign In</a></div>:<div><a href="/Settings" ><i className="fa fa-users fa-2x" style={{  color: '#000', padding: '20px'}} aria-hidden="true"></i></a></div>}
                        </div>

                        <div className="inner-content">
                            <h2>Search your {this.state.search}</h2>

                            <form action="" autoComplete="true" className="search-food-form food-search">
                                <input type="text" name="item" placeholder="Food Name Here"  />
                                <button type="submit" className="btn">Search Now</button>
                            </form>
                        </div>
                        <div className="banner-row">
                           {this.state.data.map((product) => { 
                            return (
                                <div key={product.id} onClick={() => clickHandler(product.id)}  style={{ 'cursor':'pointer' }}>
                                <div className="banner">
                                    <img src={product.image} style={{ width: "100%", height:"300px"}} alt="" srcSet=""/>
                                </div>
                                    <div className="price-row">
                                        <div className="title"><p>{product.name}</p></div>
                                        <div className="full-row">
                                            <div className="half-col"><p>${product.price}</p></div>
                                            <div className="half-col"><p>3.5</p>
                                                <span className="full-star"></span>
                                                <span className="full-star"></span>
                                                <span className="full-star"></span>
                                                <span className="full-star-half"></span>
                                                <span className="full-star-blank"></span><p>(1,035)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>)}
                            )}

                        </div>
                    </section>
                </div>
            </div>
        );
    }

}

const dispatchToProps = (dispatch) => {
  return {
    setLoadingProgress: (progress) => {
      
    },
  };
};


export default connect(dispatchToProps) (FoodSearch);