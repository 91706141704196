import React, { Component } from "react";
import Classes from "../styles/MyStore/MyStore.module.css";
//import StoreRegistrationForm from "../components/StoreRegistrationForm";
import Header from "../components/Panel/Header";
import { connect } from "react-redux";
import { VENDOR_STORE, POST_METHOD } from "../constants/apis";
import axios from "../hoc/axios";
import DisplayStore from "../components/Panel/MyStore/DisplayStore";
import { ADD_PROGRESS } from "../store/actions/LoaderActions";

// import axios from "../hoc/axios";

export class MyStore extends Component {
  state = {};
  render() {
    //REGISTER STORE
    if (!this.props.store_created) {
      window.location.href = "/StoreEdit";
    }
    const registerStore = (state) => {
      this.setState({
        StoreRegistrationSuccess: null,
        StoreRegistrationSuccess2: null,
        StoreRegistrationError: null,
      });

      if (!state.selectedFile && !this.props.store_created) {
        return this.setState({
          StoreRegistrationError: { message: "Please upload a store banner" },
        });
      }

      const data = new FormData();

      if (state.selectedFile) {
        data.append("store_image", state.selectedFile, state.selectedFile.name);
      }
      data.append("store_name", state.fields.store_name);
      data.append("address", state.fields.address);
      data.append("city", state.fields.city);
      data.append("province", state.fields.province);
      data.append("nation", state.fields.nation);
      data.append("store_type", state.fields.store_type);

      console.log("state");
      axios(
        POST_METHOD,
        VENDOR_STORE,
        data,
        this.props.setLoadingProgress
      ).then((Response) => {
        if (Response.success) {
          if (this.props.store_created) {
            window.location.href = "/MyStore?status=done";
            return this.setState({ StoreRegistrationSuccess2: true });
          }
        } else {
          this.setState({
            StoreRegistrationError: Response.message,
          });
        }
      });
    };

    const LinksList = [
      {
        name: "My Store",
        link: "/MyStore",
      },
    ];

    return (
      <section className={Classes.Parent}>
        <Header LinksList={LinksList} />
        <div className={Classes.form}>
          {this.props.store_created ? <DisplayStore {...this.props} /> : null}
        </div>
      </section>
    );
  }
}
const stateToProps = (state) => {
  return {
    name: state.user.first_name + " " + state.user.last_name,
    store_created: state.user.store_created,
  };
};

const dispatchToProps = (dispatch) => {
  return {
    setLoadingProgress: (progress) => {
      dispatch({ type: ADD_PROGRESS, progress });
    },
  };
};

export default connect(stateToProps, dispatchToProps)(MyStore);
