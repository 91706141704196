import React, { Component } from "react"
import Logo from '../../assets/logo.png'
import $ from 'jquery'
import Mac from '../../assets/mac-banner.jpg'
import Hawaiian from "../../assets/hawaiian.png"
import UserIcon from '../../assets/icon_role_user.jpg'
import queryString from 'query-string'
import { useParams } from "react-router"
import Cookies from "universal-cookie"
import NavBar from "../Panel/NavBar";

export class Search extends Component {

    state = {        
        menu:false
    };

    render(){

        
        let params = queryString.parse(this.props.location.search)
        
        const cookie = new Cookies();
        var authenticated = cookie.get("MEKey") ? true : false;

        const clickEventHandler = (menu) => {
            this.setState({
                menu: menu
            })
        }

        $(".bm-cross-button").click(function(){
            $(".navigation").removeClass("visible");
        });

        return (
            <div className="main-section-wrapper">
                <section className="layer-container">
                    <div className="navigation">
                        {authenticated===true?<NavBar onClickMenu={clickEventHandler} />:<div className="logo"><a href="/"><img src={Logo} alt=""/></a></div>}
                        
                        {authenticated===false?<div className="btn-row"><a href="/Login" className="btn">Sign In</a></div>:<div><a href="/Settings" ><i className="fa fa-users fa-2x" style={{  color: '#000', padding: '20px'}} aria-hidden="true"></i></a></div>}
                    </div>

                    <div className="inner-content">
                        <h2>Food Search</h2>
                        <br />
                        <p>What kind food are you looking for ?</p>

                        <form action="" autoComplete className="search-food-form">
                            <input type="text" placeholder="Food Name Here" />
                            <button type="submit" className="btn">Search Now</button>
                        </form>

                        <div className="tag-row">
                            <div className="tagline"><hr /><p>ONE CLICK FOOD SEARCH</p><hr /></div>
                        </div>
                        <div className="tags">
                            <a href="#">Pizza</a>
                            <a href="#">Dumpling</a>
                            <a href="#">Kebab</a>
                            <a href="#">Bulgogi</a>
                            <a href="#">Hamburger</a>
                            <a href="#">Ramen</a>
                        </div>
                        <div className="tag-row">
                            <div className="tagline"><hr /><p>RESTARANT SEARCH</p><hr /></div>
                        </div>
                        <div className="tags">
                            <a href="#">Chinese</a>
                            <a href="#">Japanese</a>
                            <a href="#">Italian</a>
                            <a href="#">Maditerian</a>
                            <a href="#">Cafe</a>
                            <a href="#">Maditerian</a>
                        </div>
                        <div className="tag-row">
                            <div className="tagline"><hr /><p>SUPERMARKET ITEMS</p><hr /></div>
                        </div>
                        <div className="tags">
                            <a href="#">Supermarket item Delivery Request</a>
                        </div>
                    </div>
                        <div className="banner">
                            <img src={Mac} alt="" srcset=""/>
                        </div>
                        <div className="banner">
                            <img src={Hawaiian} alt="" srcset=""/>
                        </div>
                        <div className="price-row">
                            <div className="title"><p>Hawaiian Pizza Family Size 16"</p></div>
                            <div className="full-row">
                                <div className="half-col"><p>$17.00</p></div>
                                <div className="half-col"><p>3.5</p>
                                    <span className="full-star"></span>
                                    <span className="full-star"></span>
                                    <span className="full-star"></span>
                                    <span className="full-star-half"></span>
                                    <span className="full-star-blank"></span><p>(1,035)</p>
                                </div>
                            </div>
                        </div>
                        <div className="banner">
                            <img src={Hawaiian} alt="" srcset=""/>
                        </div>
                        <div className="price-row">
                            <div className="title"><p>Hawaiian Pizza Family Size 16"</p></div>
                            <div className="full-row">
                                <div className="half-col">$17.00</div>
                                <div className="half-col"><p>3.5</p> 
                                    <span className="full-star"></span>
                                    <span className="full-star"></span>
                                    <span className="full-star"></span>
                                    <span className="full-star-half"></span>
                                    <span className="full-star-blank"></span><p>(1,035)</p>
                                </div>
                            </div>
                        </div>
                </section>
            </div>
        )
    }
}

export default Search
