import React, { Component } from "react";
import NavBar from "../components/Panel/NavBar";
import StoreEdit from "../components/Panel/MyStore/StoreEdit";
import { Route, Switch, Redirect } from "react-router";
import MyStore from "./MyStore";
import OrderList from "./OrderList";
import Classes from "../styles/UserLayout.module.css";
import axios from "../hoc/axios";
import { connect } from "react-redux";
import { SAVE_USER_DETAILS } from "../store/actions/UserDetails";
import MyItems from "./MyItems";
import MyGroups from "./MyGroups";
import Dashboard from "../components/Dashboard";
import Home from "../components/Home";
import Settings from "../components/Settings";
import Delivery from "../components/Delivery";
import Profile from "../components/Profile";
import Cart from "./Chart";
import Main from '../components/Main';
import FoodSearch from "../components/FoodSearch";
import StoreSearch from "../components/searchComponent/StoreSearch";
import SuperMarkets from "../components/searchComponent/SuperMarkets";
import MapScreen from "./MapScreen";
import Chat from "./Chat";
import Loader from "react-loader-spinner";
import { GET_METHOD, USER } from "../constants/apis";
import { ADD_PROGRESS } from "../store/actions/LoaderActions";
import { logoutUser } from "../store/actions/Authentication";
export class UserLayout extends Component {
  state = {
    detailsAwaited: true,
  };

  //COMPONENT DID MOUNT
  componentDidMount() {
    axios(GET_METHOD, USER, {}, this.props.setLoadingProgress).then(
      (Response) => {
        if (Response.success) {
          const { data } = Response.data;
          this.props.SaveUserDetails(data);
          this.setState({ detailsAwaited: false });
        }else{
          logoutUser();
        }
      }
    );
  }
  /*============================================================================================
                            DEFINE ROUTES FOR AUTHENTICATED USER
===============================================================================================*/
  render() {

    const clickEventHandler = (menu) => {
          
    }
    
    //ROUTES
    const routes = this.props.store_created ? (
      <Switch>
        <Route path="/Dashboard" component={Dashboard} />
        <Route path="/Delivery" component={Delivery} />
        <Route path="/Profile" component={Profile} />
        <Route path="/MyStore" component={MyStore} />
        <Route path="/StoreEdit" component={StoreEdit} />
        <Route path="/Settings" component={Settings} />
        <Route path="/cart" component={Cart} />
        <Route path="/home" component={Home} />
        <Route path="/chat-start" component={Chat} />
        <Route path="/driver-availability" component={MapScreen} />
        <Route path="/Items" component={MyItems} />
        <Route path="/MyGroups" component={MyGroups} />
        {this.props.cat_created ? (
          <Route path="/Items" component={MyItems} />
        ) : (
          <Route path="/MyGroups" component={MyGroups} />
        )}
      </Switch>
    ) : (
      <Switch>
        <Route path="/Dashboard" component={Dashboard} />
        <Route path="/Profile" component={Profile} />
        <Route path="/Delivery" component={Delivery} />
        <Route path="/order-list" component={OrderList} />
        <Route path="/StoreEdit" component={StoreEdit} />
        <Route path="/Settings" component={Settings} />
        <Route path="/cart" component={Cart} />
        <Route path="/home" component={Home} />
        <Route path="/chat-start" component={Chat} />
        <Route path="/driver-availability" component={MapScreen} />
        <Route path="/MyStore" component={MyStore} />
        <Route path="*">
          <Redirect to="/MyStore" />
        </Route>
      </Switch>
    );

    /*eslint-disable-next-line */
    {
      return this.state.detailsAwaited ? (
        <div
          className="loaderScreen"
          style={{
            width: "100%",
            height: "100",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader type="ThreeDots" color="#E8592A" height="100" width="100" />
        </div>
      ) : (
        <section className={Classes.Section}>
          <div className="row" style={{ height: "100%", overflowY: "scroll" }}>
            <div
              className="col-md-1"
              style={{
                paddingRight: "0",
                position: "relative",
              }}
            >
              {/* style={{ position: "sticky", top: "0", width: "inherit" }} */}
              <div>
                <NavBar onClickMenu={clickEventHandler}/>
              </div>
            </div>
            <div className="col-md-11" style={{ paddingLeft: "0" }}>
              <div className={Classes.Child} style={{ height: "100vh" }}>
                {routes}
              </div>
            </div>
          </div>
        </section>
      );
    }
  }
}

const dispatchToProps = (dispatch) => {
  return {
    SaveUserDetails: (data) => {
      dispatch({ type: SAVE_USER_DETAILS, data });
    },

    setLoadingProgress: (progress) => {
      dispatch({ type: ADD_PROGRESS, progress });
    },
  };
};
const stateToProps = (state) => {
  return {
    all: state,
    store_created: state.user.store_created,
    cat_created: state.user.cat_created,
  };
};
export default connect(stateToProps, dispatchToProps)(UserLayout);
