import React, { Component } from "react";
import Classes from "../../../styles/MyItems/AddItemForm.module.css";
import qs from "query-string";
import Search from "./Search";
import DeletePopup from "../../../hoc/DeletePopup";
import ViewItem from "./ViewItem";
import axios from "../../../hoc/axios";

import {
  GET_PARTICULAR_ITEM,
  EDIT_ITEM,
  ITEM_IMAGES,
  DELETE_IMAGE,
  DELETE_ITEM,
  GET_METHOD,
  DELETE_METHOD,
  POST_METHOD,
} from "../../../constants/apis";
import EditItemForm from "./EditItemForm";
import { ADD_PROGRESS } from "../../../store/actions/LoaderActions";
import { connect } from "react-redux";

export class EditItem extends Component {
  state = {
    showSearch: true,
    filterData: [],
    data: {},
  };
  componentDidMount() {
    const parsed = qs.parse(this.props.location.search);
    if (parsed.id) {
      this.setState({ showSearch: false });

      axios(
        GET_METHOD,
        GET_PARTICULAR_ITEM + "/" + parsed.id,
        {},
        this.props.setLoadingProgress
      ).then((Response) => {
        if (Response.success) {
          this.setState({ data: Response.data.data });
        }
      });
    }
  }

  render() {
    const { data } = this.props;
    const parsed = qs.parse(this.props.location.search);
    const submitDeleteImage = (id) => {
      axios(
        DELETE_METHOD,
        DELETE_IMAGE,
        { id },
        this.props.setLoadingProgress
      ).then((Response) => {
        if (Response.success) {
          return this.setState({
            EditItemSuccess: true,
          });
        }
      });
    };

    const submitEditItem = (state) => {
      this.setState({ EditItemError: "", EditItemSuccess: "" });
      const data = new FormData();
      // if (state.selectedFile) {
      //   for (var i = 0; i < state.selectedFile.length; i++) {
      //     data.append("image", state.selectedFile[i]);
      //   }
      // }

      const { name, price, group_id, sort, description } = state.fields;
      data.append("name", name);
      data.append("price", price);
      data.append("group_id", group_id);
      data.append("description", description);
      data.append("sort", sort);
      data.append("id", state.id);
      data.append("store_id", this.props.store_id);
      data.append("image", state.selectedFile[0]);
      data.append("preview1", state.selectedFile1[0]);
      data.append("preview2", state.selectedFile2[0]);
      data.append("preview3", state.selectedFile3[0]);
      data.append("preview4", state.selectedFile4[0]);

      axios(POST_METHOD, EDIT_ITEM, data, this.props.setLoadingProgress).then(
        (Response) => {
          if (!Response.success) {
            return this.setState({
              EditItemError: Response.message,
            });
          }else {
            return this.setState({
              EditItemSuccess: true,
            });
          }
        }
      );
    };

    const searchItems = (search) => {
      const filterData =
        data && search !== ""
          ? data.rows.filter((Element) => {
              return (
                Element.name.toUpperCase().indexOf(search.toUpperCase()) !== -1
              );
            })
          : [];

      this.setState({ filterData });
    };

    const DeleteItem = (dummy) => {
      axios(
        DELETE_METHOD,
        "item/delete",
        { id: parsed.id },
        this.props.setLoadingProgress
      );
    };

    return (
      <section className={Classes.Parent}>
        {this.state.showSearch ? (
          <Search searchItems={searchItems} data={this.state.filterData} />
        ) : (
          <>
            
            {Object.values(this.state.data).length !== 0 ? (
              <EditItemForm
                data={this.state.data}
                submitEditItem={submitEditItem}
                submitDeleteImage={submitDeleteImage}
                {...this.state}
              />
            ) : null}
          </>
        )}
      </section>
    );
  }
}
const dispatchToProps = (dispatch) => {
  return {
    setLoadingProgress: (progress) => {
      dispatch({ type: ADD_PROGRESS, progress });
    },
  };
};

export default connect(null, dispatchToProps)(EditItem);
