import React, { Component } from "react";
import Classes from "../styles/MyItems/MyItems.module.css";
import Header from "../components/Panel/Header";
import { connect } from "react-redux";
import axios from "../hoc/axios";
import { Route, history } from "react-router";
import {
  GET_ALL_ITEMS,
  ITEM,
  ITEM_IMAGES,
  GET_METHOD,
  POST_METHOD,
} from "../constants/apis";
import AddItemForm from "../components/Panel/MyItems/AddItemForm";
import Datatable from "../hoc/datatable";
import EditItemForm from "../components/Panel/MyItems/EditItem";
import { Link } from "react-router-dom";
import { ADD_PROGRESS } from "../store/actions/LoaderActions";
import CurrencyFormat from 'react-currency-format';

// import axios from "../hoc/axios";

class MyItems extends Component {
  state = {
    AddItemError: "",
    AddItemSuccess: "",
    data: {
      columns: [
        {
          label: "Name",
          field: "name",
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "Price",
          field: "price",
          width: 270,
        },
        {
          label: "Description",
          field: "description",
          width: 200,
        },
        {
          label: "Category",
          field: "group_name",
          width: 200,
        },
      ],
      rows: [],
    },
  };
  componentDidMount() {
    axios(GET_METHOD, GET_ALL_ITEMS, {}, this.props.setLoadingProgress).then(
      (Response) => {
        if (Response.success) {
          const data = [];
          /*eslint-disable-next-line*/
          console.log(Response);
          Response.data.data.map((Element) => {
            data.push({
              ...Element,
              link: "View",
              clickEvent: () =>
                (window.location.href = `/Items/Edit?id=${Element.id}`),
            });
          });

          this.setState({
            data: {
              columns: [...this.state.data.columns],
              rows: data,
            },
          });
        }
      }
    );
  }
  render() {
    const RefreshData = () => {
      this.setState({
        data: { columns: [...this.state.data.columns], rows: [] },
      });
      axios(GET_METHOD, GET_ALL_ITEMS, {}, this.props.setLoadingProgress).then(
        (Response) => {
          if (Response.success) {
            const data = [];
            /*eslint-disable-next-line*/
            Response.data.data.map((Element) => {
              data.push({
                ...Element,
                link: "View",
                clickEvent: () =>
                  (window.location.href = `/Items/Edit?id=${Element.id}`),
              });
            });
            this.setState({
              data: {
                columns: [...this.state.data.columns],
                rows: data,
              },
            });
          }
        }
      );
    };
    const LinksList = [
      {
        name: "My Items",
        link: "/Items",
      },
      {
        name: "Add Item",
        link: "/Items/Add",
      },
    ];

    const EditLinksList = [
      {
        name: "My Items",
        link: "/Items",
      },
      {
        name: "Edit Item",
        link: "/Items/Edit",
      },
    ];
    const { data } = this.state;

    const triggerRedirect = (id) => {
      window.location.href = "/Items/Edit?id="+id;
    };

    const clearMessages = () => {
      this.setState({ AddItemSuccess: "", AddItemError: "" });
    };

    const submitAddItem = (state) => {
      this.setState({ AddItemError: "", AddItemSuccess: "" });
    
      const data = new FormData();
      for (var i = 0; i < state.selectedFile.length; i++) {
        data.append("image", state.selectedFile[i]);
      }

      const { name, price, group_id, sort, description } = state.fields;
      data.append("name", name);
      data.append("price", price);
      data.append("group_id", group_id);
      data.append("description", description);
      data.append("sort", sort);
      data.append("store_id", this.props.store_id);
      data.append("image", state.selectedFile[0]);
      data.append("preview1", state.selectedFile1[0]);
      data.append("preview2", state.selectedFile2[0]);
      data.append("preview3", state.selectedFile3[0]);
      data.append("preview4", state.selectedFile4[0]);

      if(state.selectedFile[0]==undefined){
        this.setState({
          AddItemError: {"image": "Fetured image is required"},
        });
      }else{

        axios(POST_METHOD, ITEM, data, this.props.setLoadingProgress)
          .then((Response) => {
            RefreshData();
            if (Response.success) {
              return this.setState({
                AddItemSuccess: true,
              });
            }
          })
          .catch((error) => {
            if (error.response) {
              this.setState({
                AddItemError: error.response.data.message,
              });
            }
          });
        }
    };
    
    return (
      <section className={Classes.Parent}>
        <Route path="/Items/Add">
          <Header LinksList={LinksList} />
          <div className={Classes.Child}>
            <AddItemForm
              {...this.props}
              submitAddItem={submitAddItem}
              {...this.state}
              clearMessages={clearMessages}
            />
          </div>
        </Route>
        <Route path="/Items" exact>
          <Header LinksList={LinksList} />
          <div className={Classes.Child}>
            <div className="item-contailer">
                  <div className="heading-row">
                      <div className="column column-left"><span>My item(s)</span></div>
                      <div className="column column-right">Total: <span>{ data.rows.length }</span> items(s)</div>
                  </div>

                  { data.rows.map((row:any) => 
                    <div className="list-container" key={row.id} onClick={()=> triggerRedirect(row.id)}>
                        <div className="items-listing">
                            <div className="item-image">
                                <img src={ row.image } alt="food item" />
                            </div>
                            <div className="items-details">
                                <h4>{ row.name }</h4>
                                <ul>
                                    <li>{ row.description }</li>
                                    <li><CurrencyFormat value={row.price} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}</div>} /></li>
                                    <li>{ row.group_name }</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                  )}
                  <div className="addmore">
                      <a href="/Items/Add">ADD NEW ITEM</a>
                  </div>
              </div>         
          </div>
        </Route>
        <Route path="/Items/Edit" exact>
          <Header LinksList={EditLinksList} />
          <div className={Classes.Child}>
            <EditItemForm
              {...this.props}
              store_id={this.props.store_id}
              data={this.state.data.rows}
              submitAddItem={submitAddItem}
              {...this.state}
              clearMessages={clearMessages}
            />
          </div>
        </Route>
      </section>
    );
  }
}
const stateToProps = (state) => {
  return {
    name: state.user.first_name + " " + state.user.last_name,
    store_created: state.user.store_created,
    store_id: state.user.store_id,
  };
};
const dispatchToProps = (dispatch) => {
  return {
    setLoadingProgress: (progress) => {
      dispatch({ type: ADD_PROGRESS, progress });
    },
  };
};

export default connect(stateToProps, dispatchToProps)(MyItems);
