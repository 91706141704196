import React, { Component } from "react";
import Header from "./Panel/Header";
import Classes from "../styles/MyStore/MyStore.module.css";

export class Dashboard extends Component {
	state = {};

	render() {
		const LinksList = [
	      {
	        name: "Home Page",
	        link: "/Dashboard",
	      },
	    ];

		return (
			<section className={Classes.Parent}>
	          <Header LinksList={LinksList} />
	          <div className={Classes.form}>
	          	<h3>Home Page coming soon!</h3>
	          </div>
	         </section>
		);
	}

}


export default Dashboard;