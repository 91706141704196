import React from "react";
import Popup from "reactjs-popup";
export default (props) => (
  <Popup
    trigger={
      <i className="fa fa-trash" aria-hidden="true">
        {props.showText ? props.Text : null}
      </i>
    }
    modal
    closeOnDocumentClick
  >
    {(close) => (
      <div
        style={{
          padding: "20px 15px",
          marginBottom: "20px",
          borderRadius: "20px",
        }}
      >
        <h5>{props.Message ? props.Message : "Are you sure to delete"}</h5>{" "}
        <button
          onClick={() => {
            close();
          }}
          style={{ float: "right", color: "black", background: "#fff" }}
        >
          No
        </button>
        <button
          style={{ float: "right" }}
          onClick={() => {
            props.deleteImageHandler(props.id);
            if (props.ReloadPage) {
              setTimeout(() => {
                window.location.href = props.ReloadLink;
              }, 1000);
            }
          }}
        >
          Yes
        </button>
      </div>
    )}
  </Popup>
);
