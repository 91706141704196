import React from "react";
import "./App.css";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { connect } from "react-redux";
import UserAuthentication from "./screens/UserAuthentication";
import UserLayout from "./screens/UserLayout";
import { ADD_PROGRESS } from "./store/actions/LoaderActions";
import Search from './components/searchComponent/Search';
import Main from './components/Main';
import FoodSearch from "./components/FoodSearch";
import Product from "./components/ProductDescription";
import StoreSearch from "./components/searchComponent/StoreSearch";
import SuperMarkets from "./components/searchComponent/SuperMarkets";


function App(props) {
  /*============================================================================================
                                    ROUTES
===============================================================================================*/
  const routes = props.authenticated ? (
    <Switch>
      <Route path="/Registration" component={UserAuthentication} exact></Route>
      <Route
        path="/StoreRegistration"
        component={UserAuthentication}
        exact
      ></Route>
      <Route path="/" component={Main} exact></Route>
      <Route path="/search" component={FoodSearch} exact></Route>
      <Route path="/storeSearch" component={StoreSearch} exact></Route>
      <Route path="/supermarkets" component={SuperMarkets} exact></Route>
      <Route path="/products/:id" component={Product} exact></Route>
      <Route path="*" component={UserLayout} exact></Route>
    </Switch>
  ) : (
    <Switch>
      <Route path="/Login" component={UserAuthentication} exact></Route>
      <Route path="/Registration" component={UserAuthentication} exact></Route>
      <Route path="/" component={Main} exact></Route>
      <Route path="/search" component={FoodSearch} exact></Route>
      <Route path="/products/:id" component={Product} exact></Route>
      <Route path="/storeSearch" component={StoreSearch} exact></Route>
      <Route path="/supermarkets" component={SuperMarkets} exact></Route>
    </Switch>
  );
  /*============================================================================================
                                    JSX
===============================================================================================*/
  return (
    <BrowserRouter>
      <LoadingBar
        color={"#25b393"}
        progress={props.progress}
        onLoaderFinished={() => props.setLoadingProgress(0)}
      />
      <section className={props.progress > 0 ? "Blur" : null}>{routes}</section>
    </BrowserRouter>
    // <Chart />
    // <MapScreen />
    // <>
    //   <Search />
    //   {/* <FoodSearch /> */}
    //   {/* <StoreSearch /> */}
    // </>
  );
}

const stateToProps = (state) => {
  return {
    authenticated: state.auth.authenticated,
    progress: state.loader.loaderProgress,
  };
};
const dispatchToProps = (dispatch) => {
  return {
    setLoadingProgress: (progress) => {
      dispatch({ type: ADD_PROGRESS, progress });
    },
  };
};

export default connect(stateToProps, dispatchToProps)(App);
