import React, { Component } from "react";
import Header from "./Panel/Header";
import Classes from "../styles/MyStore/MyStore.module.css";
import axios from "../hoc/axios";
import { CHANGE_ROLE, POST_METHOD } from "../constants/apis";
import { connect } from "react-redux";
import { ADD_PROGRESS } from "../store/actions/LoaderActions";

export class Settings extends Component {
	state = {};

	render() {
		const LinksList = [
	      {
	        name: "Change my role",
	        link: "/Settings",
	      },
	    ];
	    
	    const data =  JSON.parse(localStorage.getItem("user"));
	    const handleClick = (role_id) => {
	    	const reqData = {"user_id": data.id, "role_id": role_id };
		    axios(POST_METHOD, CHANGE_ROLE, reqData , this.props.setLoadingProgress).then(
		        (Response) => {
			        if (Response.success) {
			           data.role_id = role_id;
			           localStorage.setItem("user", JSON.stringify(data));
			           if(role_id==3){
				           window.location.href = "/profile";
				       }else if(role_id==1){
				          window.location.href = "/";
				       }else if(role_id==2){
				       		window.location.href = "/MyStore";
				       }
			        }
		    });
	     }


		return (
			<section className={Classes.Parent}>
	          <Header LinksList={LinksList} />
	          <div className={Classes.form} style={{ textAlign: "center"}}>
	          	<div style={{  margin:"20px" }}>
	          		<h4>Please select role</h4>
	          	</div>
	          	<ul className="changeRole">
	          		<li className={ data.role_id==1?"active":""} onClick={() => handleClick(1)}> End User</li>
	          		<li className={ data.role_id==2?"active":""} onClick={() => handleClick(2)} > Restaurant Owner</li>
	          		<li className={ data.role_id==3?"active":""} onClick={() => handleClick(3)}> Delivery Driver</li>
	          	</ul>
	          </div>
	         </section>
		);
	}

}

const dispatchToProps = (dispatch) => {
  return {
    setLoadingProgress: (progress) => {
      
    },
  };
};

export default connect(dispatchToProps) (Settings);