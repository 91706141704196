import React from "react";
import { MDBDataTableV5 } from "mdbreact";

export default function TopSearch(props) {
  const { datatable } = props;
  return (
    <MDBDataTableV5
      hover
      info={false}
      entries={10}
      pagesAmount={4}
      data={datatable}
      searchTop={false}
      searchBottom={false}
      paging={false}
    />
  );
}
